import React, { useRef, useState } from "react";
// import MapView, { Circle, Marker } from "react-native-maps";
import { StyleSheet, Text, Image, View, ScrollView, FlatList, Dimensions, TouchableOpacity, Platform, TextInput } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { Ionicons } from "@expo/vector-icons";
import DrawerLayout from "react-native-gesture-handler/DrawerLayout";
import RNPickerSelect from "react-native-picker-select";
import Slider from "@react-native-community/slider";

import * as Animatable from "react-native-animatable";
import { useEffect } from "react";
import { firestore } from "firebase";

import algoliasearch from "algoliasearch/lite";
import { useNavigation } from "@react-navigation/native";
import { StyledText } from "../components/StyledText";
import Map from "../components/Map";
import MiniMapMobile from "../components/Map";
import StoreCard from "../components/StoreCard";

import firebase from 'firebase/app'

const client = algoliasearch("FPUF7P23Y7", "4096d8d6f649f05502ca9e678e4c677a");
let index;

const RenderDrawer = ({ indexName, setIndexName, priceTo, setPriceTo, priceFrom, setPriceFrom, distance, setDistance, categories, setCategories }) => {
	return (
		<ScrollView style={{ flex: 1 }} contentContainerStyle={{ flexGrow: 1, paddingTop: 20, paddingHorizontal: 15 }}>
			<View style={{ flexDirection: "row", alignItems: "center" }}>
				<Ionicons name="settings" size={25} />
				<StyledText style={{ fontSize: 20, fontWeight: "bold", marginLeft: 10 }}>Búsqueda Avanzada</StyledText>
			</View>
			

			<View style={{ marginTop: 20 }}>
				<StyledText style={{ fontSize: 14, color: "gray" }}>Distancia hasta {Math.round(distance / 1000)}km</StyledText>
				<View style={{ height: 40, width: "100%" }}>
					<Slider onValueChange={(ev) => setDistance(ev)} style={{ zIndex: 2, marginTop: 10, width: "100%", height: 40 }} value={distance} minimumValue={0} maximumValue={10000} minimumTrackTintColor="#FFC148" maximumTrackTintColor="#C4C4C4" />
					<View style={{ width: 12, height: 12, borderRadius: 6, backgroundColor: "#FFC148", position: "absolute", left: 2, top: 24 }} />
					<View style={{ width: 12, height: 12, borderRadius: 6, backgroundColor: "#C4C4C4", position: "absolute", right: 2, top: 24 }} />
				</View>
			</View>

			<View style={{ marginTop: 30 }}>
				<StyledText style={{ fontSize: 14, color: "gray" }}>Categorización</StyledText>
				<View style={{ width: "100%" }}>
					{categories.map((category, i) => {
						return (
							<TouchableOpacity
								style={{ marginTop: 10, flexDirection: "row", alignItems: "center" }}
								key={i}
								onPress={() => {
									const c = categories;
									c[i] = { ...c[i], value: !categories[i].value };
									setCategories([...c]);
								}}>
								<View style={{ alignItems: "center", justifyContent: "center", width: 30, marginRight: 10, height: 30, borderRadius: 6, borderColor: "#FFC148", borderWidth: 3 }}>
									<View style={{ width: 18, height: 18, borderRadius: 3, backgroundColor: categories[i].value ? "#FFC148" : "white" }} />
								</View>
								<StyledText style={{ fontSize: 16 }}>{category.name}</StyledText>
							</TouchableOpacity>
						);
					})}
				</View>
			</View>
		</ScrollView>
	);
};

const MapScreen = () => {
	const insets = useSafeAreaInsets();
	const drawerRef = useRef();
	const storeBannerRef = useRef();
	const navigation = useNavigation();

	const [indexName, setIndexName] = useState("stores");
	const [search, setSearch] = useState("");
	const [priceFrom, setPriceFrom] = useState(0);
	const [priceTo, setPriceTo] = useState(0);
	const [distance, setDistance] = useState(1000);
	const [results, setResults] = useState([{}, {}, {}]);
	const [categories, setCategories] = useState([])

	const [storeSelected, setStoreSelected] = useState(null);

	const fetchCategories = async () => {
		firebase
			.firestore()
			.collection("platform")
			.doc("sectors")
			.get()
			.then((doc) => {
				const c = doc.data().data.map((cat) => ({ ...cat, value: false }));
				setCategories(c);
			});
	};

	useEffect(() => {
		fetchCategories();
	},[])

	useEffect(() => {
		index = client.initIndex(indexName);
	}, [indexName]);

	useEffect(() => {
		storeSelected && storeBannerRef && storeBannerRef.current && (storeSelected.objectID ? storeBannerRef.current.fadeInLeft() : storeBannerRef.current.slideInUp());
	}, [storeSelected]);

	
	const getResults = async () => {
		index.search(search).then(({ hits }) => {
			console.log(hits);
			setResults(hits);
		});
	};
	return (
		<View style={styles.container}>
			<DrawerLayout
				drawerLockMode={Platform.OS === "web" ? "locked-closed" : "unlocked"}
				ref={drawerRef}
				enableTrackpadTwoFingerGesture={true}
				drawerWidth={300}
				drawerPosition={DrawerLayout.positions.Right}
				drawerType="front"
				drawerBackgroundColor="white"
				renderNavigationView={() => (
					<RenderDrawer
						indexName={indexName}
						priceFrom={priceFrom}
						priceTo={priceTo}
						categories={categories}
						distance={distance}
						setIndexName={(ev) => setIndexName(ev)}
						setPriceTo={(ev) => setPriceTo(ev)}
						setPriceFrom={(ev) => setPriceFrom(ev)}
						setDistance={(ev) => setDistance(ev)}
						setCategories={(ev) => setCategories(ev)}
					/>
				)}>
				<View style={styles.searchBackground} />
				<View style={styles.searchContainer}>
					<View style={[styles.searchBar, styles.shadow]}>
						<TextInput onSubmitEditing={() => search()} onChangeText={(ev) => setSearch(ev)} value={search} style={{ fontSize: 16, flex: 1, paddingLeft: 10, paddingRight: 50 }} placeholder="Busca en tu zona..." />
						<TouchableOpacity onPress={getResults} style={{ position: "absolute", right: 0, height: 50, width: 50, alignItems: "center", justifyContent: "center" }}>
							<Ionicons name="search" size={25} color="#4C4C4C" />
						</TouchableOpacity>
					</View>
					<TouchableOpacity onPress={() => drawerRef.current.openDrawer()} style={[styles.searchFilterButton, styles.shadow]}>
						<Ionicons name="settings" size={25} color="#4C4C4C" />
					</TouchableOpacity>
				</View>
				<FlatList
					data={results}
					style={{ flex: 1, width: "100%" }}
					contentContainerStyle={{ alignSelf:"center", maxWidth:900, width:"100%", padding: 10 }}
					keyExtractor={(item) => item.objectID}
					ItemSeparatorComponent={() => <View style={{ height: 10 }} />}
					renderItem={({ item, index }) => <StoreCard key={index} item={item} storeSelected={storeSelected} />}
					// onEndReached={() => hasMore && refineNext()}
				/>
			</DrawerLayout>
		</View>
	);
};

export default MapScreen;

const styles = StyleSheet.create({
	container: {
		flex: 1,
	},

	searchBackground: {
		height: 25,
		backgroundColor: "#FFC148",
	},
	searchContainer: {
		flexDirection: "row",
		height: 50,
		marginBottom: 0,
		marginTop: -25,
		zIndex: 10,
	},
	searchBar: {
		flex: 1,
		height: 50,
		marginRight: 10,
		borderTopRightRadius: 25,
		borderBottomRightRadius: 25,
		backgroundColor: "white",
	},
	searchFilterButton: {
		width: 60,
		height: 50,
		justifyContent: "center",
		borderTopLeftRadius: 25,
		borderBottomLeftRadius: 25,
		paddingLeft: 15,
		backgroundColor: "white",
	},

	shadow: {
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 6,
		},
		shadowOpacity: 0.15,
		shadowRadius: 8.3,

		elevation: 13,
	},
});
