import React from "react";
import { createStackNavigator } from "@react-navigation/stack";
import { useNavigationState } from "@react-navigation/native";
import RoutesScreen from "../screens/RoutesScreen";
import MapRouteScreen from "../screens/MapRouteScreen";

const Stack = createStackNavigator();

const RoutesStack = () => {
	const index = useNavigationState((state) => state.index);
	return (
		<Stack.Navigator headerMode="none">
			<Stack.Screen name="RoutesLibrary" component={RoutesScreen} />
			<Stack.Screen name="RouteMap" component={MapRouteScreen} />
		</Stack.Navigator>
	);
};

export default RoutesStack;
