import React, { useState, useRef, useContext } from "react";
import { View, Image, TouchableOpacity, Linking, StyleSheet, Dimensions, Platform, TouchableWithoutFeedback } from "react-native";
import { Ionicons, MaterialCommunityIcons, Feather } from "@expo/vector-icons";

import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useNavigation, StackActions, useNavigationState } from "@react-navigation/native";
import { StyledText } from "../components/StyledText";
import LinkButton from "./LinkButton";
import { DrawerLayout } from "react-native-gesture-handler";
import * as Animatable from "react-native-animatable";
import AuthContext from "../context/AuthContext";

const SW = Dimensions.get("window").width;
const SH = Dimensions.get("window").height;

const Header = ({ index, navigation }) => {
	const drawerRef = useRef();
	const insets = useSafeAreaInsets();
	// const navigation = useNavigation();
	const [SW, setSW] = useState(Dimensions.get("window").width);
	const [open, toogle] = useState(false);
	const { userData, state, authFunctions } = useContext(AuthContext);
	// const st = useNavigationState(s => s)

	const handleShare = (href, target) => {
		// WebBrowser.openBrowserAsync(this.props.href)
		// console.log(window.location)
		Linking.canOpenURL(href, target)
			.then((supported) => {
				if (!supported) {
					console.log("Can't handle url: " + href, target);
				} else {
					if (Platform.OS === "web") {
						return window.open(href, target);
					} else {
						return Linking.openURL(href, target);
					}
				}
			})
			.catch((err) => console.error("An error occurred", err));
	};

	const logOut = () => {
		authFunctions.signOut();
	};

	const slideIn = {
		0: {
			right: -300,
		},
		1: {
			right: 0,
		},
	};

	const slideOut = {
		0: {
			right: 0,
		},
		1: {
			right: -300,
		},
	};
	// console.log("CUAL ES EL NDICE: ", index, navigation.canGoBack(), SW < 1000, index, st.history);

	const isTopNav = () => {
		return false;
		// return navigation.canGoBack() && SW < 1000;
	};

	const isSelected = (name, i) => {
		if (Platform.OS === "web") {
			const l = window.location.pathname.replace("/", "");
			if (l === "" && name === "home") {
				return true;
			} else {
				return l.includes(name);
			}
		} else {
			return index === i;
		}
	};

	return (
		<>
			<View onLayout={() => setSW(Dimensions.get("window").width)} style={[styles.header, { paddingTop: insets.top, justifyContent: "space-between", alignItems: "center" }]}>
				{/* <View style={{flexDirection:"row", width:"100%", maxWidth:960, alignItems:"center"}}> */}
				<TouchableOpacity onPress={() => navigation.navigate("Search", { screen: "Map" })} style={styles.logo}>
					{!isTopNav() ? (
						<Image source={require("../assets/logoBlack.png")} style={styles.logo} />
					) : (
						<View style={{ flexDirection: "row", alignItems: "center" }}>
							<Ionicons size={30} name="arrow-back" color="black" />
							<StyledText style={{ fontSize: 18, color: "black", fontFamily: "DemiBold" }}>Volver</StyledText>
						</View>
					)}
				</TouchableOpacity>

				{SW < 1000 ? (
					<TouchableOpacity
						onPress={() => {
							// drawerRef.current.slideInRight(300).then(res => toogle(true))
							toogle(!open);
							// open ? toogle(false) : toogle(true);
							// open ? drawerRef.current.open() : drawerRef.current.close();
						}}
						style={{ height: 50, width: 50, alignItems: "center", justifyContent: "center" }}
					>
						<Ionicons name={open ? "close" : "md-menu"} size={35} color="black" />
					</TouchableOpacity>
				) : (
					<View style={{ flexDirection: "row", flex: 1, height: 50, justifyContent: "flex-end", alignItems: "center" }}>
						<TouchableOpacity
							onPress={() => navigation.navigate("Home")}
							style={{
								flexDirection: "row",
								height: 35,
								paddingHorizontal: 15,
								alignItems: "center",
								backgroundColor: isSelected("home", 0) ? "white" : null,
								borderRadius: 20,
								justifyContent: "center",
							}}
						>
							<MaterialCommunityIcons name="home" size={25} color="#4C4C4C" style={{ marginRight: 5, opacity: isSelected("home", 0) ? 1 : 0.3 }} />
							<StyledText style={{ opacity: isSelected("home", 0) ? 1 : 0.3 }}>Inicio</StyledText>
						</TouchableOpacity>

						<TouchableOpacity
							onPress={() => navigation.navigate("Search", { screen: "Map" })}
							style={{
								flexDirection: "row",
								height: 35,
								paddingHorizontal: 15,
								backgroundColor: isSelected("search", 1) ? "white" : null,
								borderRadius: 20,
								alignItems: "center",
								justifyContent: "center",
							}}
						>
							<Feather name="map-pin" size={25} color="#4C4C4C" style={{ marginRight: 5, opacity: isSelected("search", 1) ? 1 : 0.3 }} />
							<StyledText style={{ opacity: isSelected("search", 1) ? 1 : 0.3 }}>Comercios</StyledText>
						</TouchableOpacity>

						{/* <TouchableOpacity
							onPress={() => navigation.navigate("Featured")}
							style={{
								flexDirection: "row",
								height: 35,
								paddingHorizontal: 15,
								alignItems: "center",
								backgroundColor: isSelected("featured", 2) ? "white" : null,
								borderRadius: 20,
								justifyContent: "center",
							}}
						>
							<Ionicons name="star-outline" size={30} color="#4C4C4C" style={{ marginRight: 5, opacity: isSelected("featured", 2) ? 1 : 0.3 }} />
							<StyledText style={{ opacity: isSelected("featured", 2) ? 1 : 0.3 }}>Destacados</StyledText>
						</TouchableOpacity> */}

						<TouchableOpacity
							onPress={() => navigation.navigate("Routes", { screen: "RoutesLibrary" })}
							style={{
								flexDirection: "row",
								height: 35,
								paddingHorizontal: 15,
								alignItems: "center",
								backgroundColor: isSelected("routes", 3) ? "white" : null,
								borderRadius: 20,
								justifyContent: "center",
							}}
						>
							<Ionicons name="git-compare-outline" size={30} color="#4C4C4C" style={{ marginRight: 5, opacity: isSelected("routes", 3) ? 1 : 0.3 }} />
							<StyledText style={{ opacity: isSelected("routes", 3) ? 1 : 0.3 }}>Rutas</StyledText>
						</TouchableOpacity>

						<TouchableOpacity
							onPress={() => navigation.navigate("Profile")}
							style={{
								flexDirection: "row",
								height: 35,
								paddingHorizontal: 15,
								alignItems: "center",
								backgroundColor: isSelected("user", 4) ? "white" : null,
								borderRadius: 20,
								justifyContent: "center",
							}}
						>
							<Ionicons name="person-circle-outline" size={30} color="#4C4C4C" style={{ marginRight: 5, opacity: isSelected("user", 4) ? 1 : 0.3 }} />
							<StyledText style={{ opacity: isSelected("user", 4) ? 1 : 0.3 }}>Mi Cuenta</StyledText>
						</TouchableOpacity>

						<TouchableOpacity
							onPress={() => navigation.navigate("Register")}
							style={{
								flexDirection: "row",
								height: 35,
								paddingHorizontal: 15,
								backgroundColor: isSelected("register", 0) ? "white" : null,
								borderRadius: 20,
								alignItems: "center",
							}}
						>
							<Feather name="layers" size={25} style={{ marginRight: 5, opacity: isSelected("register", 0) ? 1 : 0.3 }} />
							<StyledText style={{ opacity: isSelected("register", 0) ? 1 : 0.3 }}>Participa</StyledText>
						</TouchableOpacity>

						{/* <TouchableOpacity onPress={() => navigation.navigate("Search", {screen: "Association", params: { id: "FicKrwak7DaVb2nRI2vH" }})} style={{ flexDirection: "row", height: 35, paddingHorizontal: 15, backgroundColor: index === 4 ? "white" : null, borderRadius: 20, alignItems: "center" }}>
							<Feather name="layers" size={25} style={{ marginRight: 5, opacity: index === 4 ? 1 : 0.3 }} />
							<StyledText style={{ opacity: index === 4 ? 1 : 0.3 }}>Asociación</StyledText>
						</TouchableOpacity> */}

						<TouchableOpacity
							onPress={() => handleShare("https://club.retailhub.es/")}
							style={{
								flexDirection: "row",
								height: 35,
								paddingHorizontal: 15,
								alignItems: "center",
								backgroundColor: index === 10 ? "white" : null,
								borderRadius: 20,
								justifyContent: "center",
							}}
						>
							<Ionicons name="md-lock-closed" size={25} color="#4C4C4C" style={{ marginRight: 5, opacity: index === 10 ? 1 : 0.3 }} />
							<StyledText style={{ opacity: index === 10 ? 1 : 0.3 }}>Club del Comercio</StyledText>
						</TouchableOpacity>

						{state.authed && (
							<TouchableOpacity
								onPress={() => logOut()}
								style={{ flexDirection: "row", height: 50, paddingHorizontal: 15, alignItems: "center", backgroundColor: index === 10 ? "#ffc148" : null, borderRadius: 20 }}
							>
								<Ionicons name="exit" size={25} color={index === 10 ? "white" : "#4C4C4C"} style={{ marginRight: 5, opacity: 1 }} />
								<StyledText style={{ color: index === 10 ? "white" : null }}>Cerrar Sesión</StyledText>
							</TouchableOpacity>
						)}
					</View>
				)}
				{/* </View> */}
			</View>
			{open && (
				<Animatable.View
					animation="fadeIn"
					duration={300}
					style={{
						zIndex: 100,
						width: SW,
						height: SH,
						position: Platform.OS === "web" ? "fixed" : "absolute",
						right: 0,
						top: 0,
						bottom: 0,
						left: 0,
						backgroundColor: "rgba(0,0,0,.6)",
					}}
				>
					<TouchableWithoutFeedback onPress={() => drawerRef.current.animate(slideOut).then((res) => toogle(false))}>
						<View
							style={{
								width: "100%",
								height: "100%",
								position: Platform.OS === "web" ? "fixed" : "absolute",
								right: 0,
								top: 0,
								bottom: 0,
								left: 0,
								backgroundColor: "rgba(0,0,0,.6)",
							}}
						/>
					</TouchableWithoutFeedback>
					<Animatable.View
						animation={slideIn}
						duration={300}
						ref={drawerRef}
						style={{
							paddingTop: insets.top ? insets.top : 0,
							width: 300,
							height: "100%",
							position: Platform.OS === "web" ? "fixed" : "absolute",
							right: 0,
							top: 0,
							bottom: 0,
							backgroundColor: "white",
						}}
					>
						<View style={{ height: 50, flexDirection: "row" }}>
							<TouchableOpacity
								style={{ alignItems: "center", justifyContent: "center", width: 50, height: 50 }}
								onPress={() => drawerRef.current.animate(slideOut).then((res) => toogle(false))}
							>
								<Ionicons name="close" size={35} />
							</TouchableOpacity>
						</View>
						<View style={{ paddingHorizontal: 10, flex: 1 }}>
							<TouchableOpacity
								onPress={() => {
									drawerRef.current.animate(slideOut).then((res) => {
										toogle(false);
										navigation.navigate("Home");
									});
								}}
								style={{
									flexDirection: "row",
									height: 35,
									paddingHorizontal: 15,
									alignItems: "center",
									backgroundColor: isSelected("home", 0) ? "white" : null,
									borderRadius: 20,
									justifyContent: "center",
								}}
							>
								<MaterialCommunityIcons name="home" size={25} color="#4C4C4C" style={{ marginRight: 5, opacity: isSelected("home", 0) ? 1 : 0.3 }} />
								<StyledText style={{ opacity: isSelected("home", 0) ? 1 : 0.3 }}>Inicio</StyledText>
							</TouchableOpacity>

							<TouchableOpacity
								onPress={() => {
									drawerRef.current.animate(slideOut).then((res) => {
										toogle(false);
										navigation.navigate("Search", { screen: "Map" });
									});
								}}
								style={{
									flexDirection: "row",
									height: 35,
									paddingHorizontal: 15,
									backgroundColor: isSelected("search", 1) ? "white" : null,
									borderRadius: 20,
									alignItems: "center",
									justifyContent: "center",
								}}
							>
								<Feather name="map-pin" size={25} color="#4C4C4C" style={{ marginRight: 5, opacity: isSelected("search", 1) ? 1 : 0.3 }} />
								<StyledText style={{ opacity: isSelected("search", 1) ? 1 : 0.3 }}>Comercios</StyledText>
							</TouchableOpacity>

							{/* <TouchableOpacity
								onPress={() => {
									drawerRef.current.animate(slideOut).then((res) => {
										toogle(false);
										navigation.navigate("Featured");
									});
								}}
								style={{
									flexDirection: "row",
									height: 35,
									paddingHorizontal: 15,
									alignItems: "center",
									backgroundColor: isSelected("featured", 2) ? "white" : null,
									borderRadius: 20,
									justifyContent: "center",
								}}
							>
								<Ionicons name="star-outline" size={30} color="#4C4C4C" style={{ marginRight: 5, opacity: isSelected("featured", 2) ? 1 : 0.3 }} />
								<StyledText style={{ opacity: isSelected("featured", 2) ? 1 : 0.3 }}>Destacados</StyledText>
							</TouchableOpacity> */}

							<TouchableOpacity
								onPress={() => {
									drawerRef.current.animate(slideOut).then((res) => {
										toogle(false);
										navigation.navigate("Routes", { screen: "RoutesLibrary" });
									});
								}}
								style={{
									flexDirection: "row",
									height: 35,
									paddingHorizontal: 15,
									alignItems: "center",
									backgroundColor: isSelected("routes", 3) ? "white" : null,
									borderRadius: 20,
									justifyContent: "center",
								}}
							>
								<Ionicons name="git-compare-outline" size={30} color="#4C4C4C" style={{ marginRight: 5, opacity: isSelected("routes", 3) ? 1 : 0.3 }} />
								<StyledText style={{ opacity: isSelected("routes", 3) ? 1 : 0.3 }}>Rutas</StyledText>
							</TouchableOpacity>

							<TouchableOpacity
								onPress={() => {
									drawerRef.current.animate(slideOut).then((res) => {
										toogle(false);
										navigation.navigate("Profile");
									});
								}}
								style={{
									flexDirection: "row",
									height: 35,
									paddingHorizontal: 15,
									alignItems: "center",
									backgroundColor: isSelected("user", 4) ? "white" : null,
									borderRadius: 20,
									justifyContent: "center",
								}}
							>
								<Ionicons name="person-circle-outline" size={30} color="#4C4C4C" style={{ marginRight: 5, opacity: isSelected("user", 4) ? 1 : 0.3 }} />
								<StyledText style={{ opacity: isSelected("user", 4) ? 1 : 0.3 }}>Mi Cuenta</StyledText>
							</TouchableOpacity>

							<TouchableOpacity
								onPress={() => {
									drawerRef.current.animate(slideOut).then((res) => {
										toogle(false);
										navigation.navigate("Register");
									});
								}}
								style={{
									flexDirection: "row",
									height: 35,
									paddingHorizontal: 15,
									backgroundColor: isSelected("register", 0) ? "white" : null,
									borderRadius: 20,
									alignItems: "center",
								}}
							>
								<Feather name="layers" size={25} style={{ marginRight: 5, opacity: isSelected("register", 0) ? 1 : 0.3 }} />
								<StyledText style={{ opacity: isSelected("register", 0) ? 1 : 0.3 }}>Participa</StyledText>
							</TouchableOpacity>

							{/* <TouchableOpacity onPress={() => navigation.navigate("Search", {screen: "Association", params: { id: "FicKrwak7DaVb2nRI2vH" }})} style={{ flexDirection: "row", height: 35, paddingHorizontal: 15, backgroundColor: index === 4 ? "white" : null, borderRadius: 20, alignItems: "center" }}>
							<Feather name="layers" size={25} style={{ marginRight: 5, opacity: index === 4 ? 1 : 0.3 }} />
							<StyledText style={{ opacity: index === 4 ? 1 : 0.3 }}>Asociación</StyledText>
						</TouchableOpacity> */}

							<TouchableOpacity
								onPress={() => handleShare("https://club.retailhub.es/")}
								style={{
									flexDirection: "row",
									height: 35,
									paddingHorizontal: 15,
									alignItems: "center",
									backgroundColor: index === 10 ? "white" : null,
									borderRadius: 20,
									justifyContent: "center",
								}}
							>
								<Ionicons name="md-lock-closed" size={25} color="#4C4C4C" style={{ marginRight: 5, opacity: index === 10 ? 1 : 0.3 }} />
								<StyledText style={{ opacity: index === 10 ? 1 : 0.3 }}>Club del Comercio</StyledText>
							</TouchableOpacity>

							{state.authed && (
								<TouchableOpacity
									onPress={() => logOut()}
									style={{ flexDirection: "row", height: 50, paddingHorizontal: 15, alignItems: "center", backgroundColor: index === 10 ? "#ffc148" : null, borderRadius: 20 }}
								>
									<Ionicons name="exit" size={25} color={index === 10 ? "white" : "#4C4C4C"} style={{ marginRight: 5, opacity: 1 }} />
									<StyledText style={{ color: index === 10 ? "white" : null }}>Cerrar Sesión</StyledText>
								</TouchableOpacity>
							)}
						</View>
					</Animatable.View>
				</Animatable.View>
			)}
		</>
	);
};

export default Header;

const styles = StyleSheet.create({
	header: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		paddingLeft: 15,
		paddingRight: 5,
		minHeight: 55,
		backgroundColor: "#FFC148",
		width: "100%",
		zIndex: 0,
	},
	logo: {
		height: 40,
		width: 150,
	},
});
