import React, { useState } from "react";
import { Image, View, StyleSheet, ScrollView, TouchableOpacity, TextInput, Dimensions, Modal, Platform, ActivityIndicator } from "react-native";
import { StyledText } from "../components/StyledText";
import { useNavigation } from "@react-navigation/native";
import MenuLineComponent from "../components/MenuLineComponent";
import { useContext } from "react";
import AuthContext from "../context/AuthContext";
import LinkButton from "../components/LinkButton";
import * as Animatable from "react-native-animatable";

const SW = Dimensions.get("screen").width;
const ProfileScreen = () => {
	const navigation = useNavigation();
	const [tab, setTab] = useState(0);
	const [loading, setLoading] = useState(false);
	const { userData, state, authFunctions } = useContext(AuthContext);
	// console.log(userData, state.authed);

	const [form, setForm] = useState({
		identifier: "",
		password: "",
	});

	const logIn = async () => {
		setLoading(true);
		authFunctions.signIn({ identifier: form.identifier.trim(), password: form.password.trim() }).then((res) => {});
		setTimeout(() => {
			setLoading(false);
		},1000);
	};
	// console.log(userData, !state.authed)

	return (
		<ScrollView style={{ backgroundColor: "white" }} contentContainerStyle={{ flexGrow: 1, alignSelf: "center", width: "100%", maxWidth: 900 }}>
			{/* {!state.authed && (
				<Modal style={{ flex: 1, position: Platform.OS === "web" ? "fixed" : "absolute", zIndex: 100, border: 0, flex: 1, height:"100%", width:"100%", top: 0, bottom: 0, left: 0, right: 0, backgroundColor: "rgba(0,0,0,.6)" }} animationType="slide" transparent={true} visible={!state.authed}>
					<View style={{ flex: 1, position: Platform.OS === "web" ? "fixed" : "absolute", zIndex: 100, border: 0, flex: 1, height:"100%", width:"100%", top: 0, bottom: 0, left: 0, right: 0, backgroundColor: "rgba(0,0,0,.6)" }}/>
					<View style={{  zIndex: 200, flex: 1, alignItems: "center", justifyContent: "center" }}>
						<View style={{ backgroundColor: "white", borderRadius: 15, width: "100%", maxWidth: 300, padding: 15, paddingBottom:25 }}>
							<StyledText style={{ fontSize: 20, marginTop: 10, fontWeight: "700" }}>Este área es exclusiva para miembros de Retail Hub</StyledText>
							<View style={{ marginTop: 10 }}>
								<TextInput onChangeText={(ev) => setForm({ ...form, identifier: ev })} style={{ paddingLeft: 10, paddingRight: 10, marginTop: 15, height: 45, width: "100%", borderRadius: 22, borderWidth: 1, borderColor: "gainsboro" }} placeholder="Nombre de usuario" />
								<TextInput onChangeText={(ev) => setForm({ ...form, password: ev })} secureTextEntry={true} style={{ paddingLeft: 10, paddingRight: 10, marginTop: 15, height: 45, width: "100%", borderRadius: 22, borderWidth: 1, borderColor: "gainsboro" }} placeholder="Contraseña" />
								<LinkButton opacity={false}>
									<TouchableOpacity onPress={logIn} style={[styles.shadow, { backgroundColor: "#ffc148", paddingHorizontal: 15, height: 40, marginTop: 15, borderRadius: 7, justifyContent: "center" }]}>
										<StyledText style={{ color: "white", fontSize: 16, textAlign: "center", fontWeight: "700" }}>{loading ? "Accediendo" : "Acceder"}</StyledText>
									</TouchableOpacity>
								</LinkButton>
								<TouchableOpacity style={{ height: 35, justifyContent: "center", alignItems: "center" }}>
									<StyledText style={{ textAlign: "center" }}>Recuperar Contraseña</StyledText>
								</TouchableOpacity>
							</View>
						</View>
					</View>
				</Modal>
			)} */}
			{!state.authed ? (
				<>
					<Animatable.View animation="fadeInUp" style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
						<View style={{ backgroundColor: "white", borderRadius: 15, width: "100%", maxWidth: 380, padding: 15, paddingBottom: 25 }}>
							<StyledText style={{ fontSize: 20, marginTop: 10, fontWeight: "700", textAlign: "center" }}>Este área es exclusiva para miembros de Retail Hub</StyledText>
							<View style={{ marginTop: 10 }}>
								<TextInput
									autoCapitalize="none"
									onChangeText={(ev) => setForm({ ...form, identifier: ev })}
									style={{ fontFamily: "Regular", paddingLeft: 10, paddingRight: 10, marginTop: 15, height: 45, width: "100%", borderRadius: 22, borderWidth: 1, borderColor: "gainsboro" }}
									placeholder="Nombre de usuario"
								/>
								<TextInput
									autoCapitalize="none"
									onChangeText={(ev) => setForm({ ...form, password: ev })}
									secureTextEntry={true}
									style={{ fontFamily: "Regular", paddingLeft: 10, paddingRight: 10, marginTop: 15, height: 45, width: "100%", borderRadius: 22, borderWidth: 1, borderColor: "gainsboro" }}
									placeholder="Contraseña"
								/>
								<LinkButton opacity={false}>
									<TouchableOpacity onPress={logIn} style={[styles.shadow, { backgroundColor: "#ffc148", paddingHorizontal: 15, height: 40, marginTop: 15, borderRadius: 7, justifyContent: "center" }]}>
										{loading ? <ActivityIndicator color="white" /> : <StyledText style={{ color: "white", fontSize: 16, textAlign: "center", fontWeight: "700" }}> Acceder</StyledText>}
									</TouchableOpacity>
								</LinkButton>
								<TouchableOpacity onPress={() => navigation.navigate("Register")} style={{ height: 45, marginTop: 20, borderWidth: 4, borderColor: "#ffc148", borderRadius: 5, justifyContent: "center", alignItems: "center" }}>
									<StyledText style={{ textAlign: "center", color: "#ffc148", fontFamily: "DemiBold" }}>Quiero Registrarme</StyledText>
								</TouchableOpacity>
							</View>
						</View>
					</Animatable.View>
					<View style={{ backgroundColor: "#f3f3f3", alignItems: "center", paddingBottom: 10, paddingTop: 15 }}>
						<View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between", maxWidth: 600, height: 60, width: "100%", paddingHorizontal: 10 }}>
							<Image style={{ resizeMode: "contain", flex: 1, height: 50, maxWidth: 130 }} source={require("../assets/logoBlack.png")} />
							<Image style={{ resizeMode: "contain", flex: 1, height: 50, maxWidth: 100, marginHorizontal: 20 }} source={require("../assets/logoCocem.png")} />
							<Image style={{ resizeMode: "contain", flex: 1, height: 50, maxWidth: 130 }} source={require("../assets/logoMadrid.png")} />
						</View>
					</View>
				</>
			) : (
				<Animatable.View animation="fadeInUp" style={{ flex: 1 }}>
					<View style={styles.headerContainer}>
						<View style={styles.headerOverlay} />
						<Image style={styles.headerBackground} source={{ uri: "https://images.pexels.com/photos/1488463/pexels-photo-1488463.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" }} />
						{/* <StyledText style={styles.title}>Titulo de la tienda</StyledText> */}
					</View>
					<View style={styles.tabSelector}>
						<TouchableOpacity onPress={() => setTab(0)} style={[styles.tab, tab === 0 ? styles.tabSelected : null]}>
							<StyledText style={[styles.tabText, tab === 0 ? styles.tabTextSelected : null]}>Mi cuenta</StyledText>
						</TouchableOpacity>
						<TouchableOpacity onPress={() => setTab(1)} style={[styles.tab, tab === 1 ? styles.tabSelected : null]}>
							<StyledText style={[styles.tabText, tab === 1 ? styles.tabTextSelected : null]}>Mi Comercio</StyledText>
						</TouchableOpacity>
					</View>

					<View style={styles.productsContainer}>
						{tab === 0 ? (
							<View style={{ width: "100%" }}>
								<MenuLineComponent index={0} title="Mi información" />
								<MenuLineComponent index={0} title="Mis cupones" />
								<MenuLineComponent index={0} title="Mis transacciones" />
							</View>
						) : (
							<View style={{ width: "100%" }}>
								<MenuLineComponent index={0} title="Mi negocio" />
								<MenuLineComponent index={0} title="Mis promociones" />
								<MenuLineComponent index={0} title="Canjear promociones" />
							</View>
						)}
					</View>
				</Animatable.View>
			)}
		</ScrollView>
	);
};

export default ProfileScreen;

const styles = StyleSheet.create({
	headerContainer: {
		height: 100,
		width: "100%",
		borderBottomLeftRadius: 10,
		borderBottomRightRadius: 10,
		overflow: "hidden",
		alignItems: "center",
		justifyContent: "center",
	},
	headerOverlay: {
		flex: 1,
		position: "absolute",
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
		backgroundColor: "rgba(0,0,0,0.5)",
		zIndex: 2,
	},
	headerBackground: {
		resizeMode: "cover",
		flex: 1,
		position: "absolute",
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
		zIndex: 1,
	},
	buttonsRow: {
		flexDirection: "row",
		paddingHorizontal: 15,
		marginTop: -25,
		zIndex: 2,
	},
	button: {
		height: 50,
		width: 50,
		backgroundColor: "white",
		alignItems: "center",
		justifyContent: "center",
		borderRadius: 7,
		marginRight: 10,
	},
	bodyContainer: {
		padding: 15,
	},
	title: {
		fontSize: 24,
		fontWeight: "700",
		color: "white",
		zIndex: 4,
		marginBottom: 15,
	},
	searchSection: {
		backgroundColor: "#f3f3f3",
		paddingHorizontal: 15,
		paddingTop: 50,
		marginTop: -25,
		zIndex: 1,
	},
	searchBar: {
		height: 45,
		flexDirection: "row",
		backgroundColor: "white",
		borderRadius: 22.5,
		position: "relative",
	},
	searchButton: {
		width: 45,
		height: 45,
		alignItems: "center",
		justifyContent: "center",
		position: "absolute",
		right: 0,
		top: 0,
		zIndex: 10,
	},
	body: {
		marginTop: 15,
	},
	tabSelector: {
		flexDirection: "row",

		backgroundColor: "#f3f3f3",
	},
	tab: {
		borderBottomWidth: 0,
		flex: 1,
		height: 45,
		alignItems: "center",
		justifyContent: "center",
		borderBottomColor: "#FFC148",
	},
	tabSelected: {
		borderBottomWidth: 4,
		flex: 1,
		borderBottomColor: "#FFC148",
	},
	tabText: {
		fontSize: 18,
		fontWeight: "700",
		opacity: 0.2,
	},
	tabTextSelected: {
		opacity: 1,
		color: "#FFC148",
	},
	productsContainer: {
		flexDirection: "row",
		flexWrap: "wrap",
		padding: 10,
	},

	shadow: {
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 6,
		},
		shadowOpacity: 0.15,
		shadowRadius: 8.3,

		elevation: 13,
	},
});
