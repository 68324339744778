import React from "react";
import { View, StyleSheet } from "react-native";

import { Feather, MaterialCommunityIcons } from "@expo/vector-icons";
import LinkButton from "./LinkButton";
import { StackActions } from "@react-navigation/native";

const ButtonsAssociation = ({ id, index }) => {
	return (
		<View style={{ maxWidth: 600, width: "100%", zIndex: 10 }}>
			<View style={styles.buttonsRow}>
				<LinkButton opacity={false} action={() => StackActions.push("Association", { id })} to={"/association/" + id}>
					<View style={[styles.shadow, styles.button]}>
						<Feather color={index === 0 ?  "#FFC148" : null}  name="map-pin" size={30} />
					</View>
				</LinkButton>
				<LinkButton opacity={false} action={() => StackActions.push("MicrositeScreen", { id })} to={"/association/" + id + "/news"}>
					<View style={[styles.shadow, styles.button]}>
						<Feather color={index === 1 ? "#FFC148" : null} name="file-text" size={30} />
					</View>
				</LinkButton>
				{/* <LinkButton opacity={false} action={() => StackActions.push("PromotionsScreen", { id })} to={"/association/" + id + "/promotions"}>
					<View style={[styles.shadow, styles.button]}>
						<MaterialCommunityIcons color={index === 2 ? "#FFC148" : null}  name="ticket-percent-outline" size={30} />
					</View>
				</LinkButton> */}
			</View>
		</View>
	);
};

export default ButtonsAssociation;

const styles = StyleSheet.create({
	buttonsRow: {
		flexDirection: "row",
		paddingHorizontal: 15,
		marginTop: -25,
		zIndex: 2,
	},

	button: {
		height: 50,
		width: 50,
		backgroundColor: "white",
		alignItems: "center",
		justifyContent: "center",
		borderRadius: 7,
		marginRight: 10,
	},

	shadow: {
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 6,
		},
		shadowOpacity: 0.15,
		shadowRadius: 8.3,

		elevation: 13,
	},
});
