import React, { useState, useEffect, useRef, useReducer, useMemo } from "react";
import { Platform, TouchableOpacity, View, StyleSheet, Alert } from "react-native";
import { SafeAreaProvider } from "react-native-safe-area-context";

import AuthReducer from "./reducers/AuthReducer";
import AuthContext from "./context/AuthContext";

import AsyncStorage from "@react-native-async-storage/async-storage";

import * as Animatable from "react-native-animatable";
import * as Font from "expo-font";
import * as Linking from "expo-linking";

import "react-native-gesture-handler";
import { NavigationContainer, useLinking } from "@react-navigation/native";

import { firebase } from "@firebase/app";
import { auth, firestore } from "firebase";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";

import { enableScreens } from "react-native-screens";

import { StyledText } from "./components/StyledText";

import { FontAwesome5 } from "@expo/vector-icons";
import MainTabNavigator from "./navigators/MainTabNavigator";
import Header from "./components/Header";

enableScreens();

const prefix = Linking.makeUrl("/");

// Initialize Firebase
const firebaseConfig = {
	apiKey: "AIzaSyA7522FCbabm1HahH84jN8_GKK6uw4lJmE",
	authDomain: "retailhub-6602d.firebaseapp.com",
	projectId: "retailhub-6602d",
	storageBucket: "retailhub-6602d.appspot.com",
	messagingSenderId: "3759132075",
	appId: "1:3759132075:web:d8186d741b3ae579ec96ee",
	measurementId: "G-QB35SPW44R",
};

firebase.initializeApp(firebaseConfig);

const config = {
	screens: {
		Search: {
			path: "search",
			screens: {
				Map: "",
				Store: "store/:id",
				ArticleScreen: "article/:id",
				Association: "association/:id",
				MicrositeScreen: "association/:id/news",
				PromotionsScreen: "store/:id/promotions",
				PromotionScreen: "promotion/:id",
				ProductsScreen: "store/:id/products",
				ProductScreen: "product/:id"
			},
		},
		// Promotions: {
		// 	path:"search/promotions",
		// 	screens:{
		// 		PromotionsSearchScreen:""
		// 	}
		// },
		Featured: {
			path: "featured"
		},
		Routes: {
			path: "routes",
			screens:{
				RoutesLibrary: "",
				RouteMap: "map/:id"
			}
		},
		Register: {
			path: "register",
		},
		Page: {
			path: "page/:id",
		},
		Home: {
			path: "",
			name: "Retail Hub - Inicio",
		},
		Profile: {
			path: "user",
			screens: {
				ProfileScreen: "",
			},
		},
		NotFound: "*",
	},
};

export default function App() {
	const routeNameRef = useRef();
	const navigationRef = useRef(null);

	const initialState = {
		isLoading: true,
		authed: false,
		resetPass: false,
	};

	const [initRoute, setInitRoute] = useState();
	const [navigationState, setNavigationState] = useState();
	const [state, dispatch] = useReducer(AuthReducer, initialState);
	const [index, setIndex] = useState(0);
	const [pages, setPages] = useState([]);
	const [userData, setUserData] = useState({});
	const [cookieConsent, setCookieConsent] = useState(false);

	const { getInitialState } = useLinking(navigationRef, {
		prefixes: [prefix, "retailhub://"],
		config,
	});

	useEffect(() => {
		bootstrapAsync();
		fetchPages();
		checkCookieconsent();
	}, []);

	const checkCookieconsent = async () => {
		const c = await AsyncStorage.getItem("cookies");
		const cookies = c ? JSON.parse(c) : false;
		!cookies && setCookieConsent(true);
	};

	const acceptCookies = async () => {
		AsyncStorage.setItem("cookies", JSON.stringify({ cookies: true, show: false }));
		setCookieConsent(false);
	};
	const denyCookies = async () => {
		AsyncStorage.setItem("cookies", JSON.stringify({ cookies: false, show: false }));
		setCookieConsent(false);
	};

	useEffect(() => {
		Platform.OS === "web" &&
			getInitialState()
				.catch(() => {})
				.then((state) => {
					// console.log(state);
					if (state !== undefined) {
						setInitRoute(state);
					}
					// setIsReady(true);
				});
	}, [navigationState]);

	const bootstrapAsync = async () => {
		auth().onAuthStateChanged(async (user) => {
			if (user) {
				await firestore()
					.collection("users")
					.doc(user.uid)
					.get()
					.then((doc) => {
						if (doc.exists) {
							const data = { ...doc.data(), id: doc.id, ref: doc.ref };
							setUserData(data);
							dispatch({ type: "RESTORE_TOKEN", authed: true, resetPass: false });
						} else {
							Alert.alert("No se ha encontrado ningún usuario con estas credenciales");
						}
					})
					.catch((err) => console.log(err));
			} else {
				// No user is signed in.
				dispatch({ type: "RESTORE_TOKEN", resetPass: false, authed: false });
			}
		});
	};

	const authFunctions = useMemo(
		() => ({
			signIn: async (form) => {
				const email = form.identifier.trim().toLocaleLowerCase();
				const password = form.password.trim();
				auth()
					.signInWithEmailAndPassword(email, password)
					.then(async (re) => {
						firestore()
							.collection("users")
							.doc(re.user.uid)
							.get()
							.then((doc) => {
								if (doc.exists) {
									const data = { ...doc.data(), id: doc.id, ref: doc.ref };

									setUserData(data);
									dispatch({ type: "RESTORE_TOKEN", authed: true, resetPass: false });
								} else {
									// alert("No se ha encontrado ningún usuario con estas credenciales");
									Platform.OS === "web"
										? alert("No se ha encontrado ningún usuario con estas credenciales")
										: Alert.alert("No se ha encontrado ningún usuario con estas credenciales");
									auth()
										.signOut()
										.then(async () => {
											dispatch({ type: "SIGN_OUT" });
										});
								}
							});
					})
					.catch((err) => {
						console.log(err[0]);
						Platform.OS === "web" ? alert(err[0]) : Alert.alert("Error al iniciar la sesión, el usuario o contraseña pueden ser incorrectos", "", {}, {});
					});
			},

			signOut: async (data) => {
				auth()
					.signOut()
					.then(async () => {
						dispatch({ type: "SIGN_OUT" });
					})
					.catch((error) => {
						alert(error.message);
					});
			},

			changeConfirmed: async (data) => {
				dispatch({ type: "CHANGE_CONFIRMED", confirmed: data });
			},
		}),
		[]
	);

	const getActiveRouteName = (state) => {
		const route = state.routes[state.index];
		if (route.state) {
			// Dive into nested navigators
			return getActiveRouteName(route.state);
		}

		return {
			name: route.name,
			id: route.params ? route.params.id : null,
			...route,
		};
	};

	let [fontsLoaded] = Font.useFonts({
		Regular: require("./assets/fonts/MRR.otf"),
		DemiBold: require("./assets/fonts/MRSB.otf"),
		Bold: require("./assets/fonts/MRB.otf"),
	});

	const fetchPages = async () => {
		firestore()
			.collection("pages")
			.where("draft", "==", false)
			.get()
			.then((query) => {
				const p = query.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
				setPages(p);
			});
	};

	if (!fontsLoaded) {
		return null;
	} else {
		return (
			<SafeAreaProvider>
				<NavigationContainer
					ref={navigationRef}
					initialState={initRoute}
					onStateChange={async (state) => {
						setIndex(state.index);
						setNavigationState(state);
						const previousRouteName = routeNameRef.current;
						const currentRouteName = getActiveRouteName(state);
						if (previousRouteName !== currentRouteName.name) {
							// customAnalytics(currentRouteName)
							// await Analytics.setCurrentScreen(currentRouteName.name);
						}
						routeNameRef.current = currentRouteName.name;
					}}
				>
					<AuthContext.Provider value={{ state, authFunctions, userData, pages }}>
						<Header index={index} navigation={navigationRef.current} />
						<MainTabNavigator />
					</AuthContext.Provider>
				</NavigationContainer>

				{cookieConsent && Platform.OS === "web" && (
					<Animatable.View
						animation="fadeInUp"
						style={[styles.shadow, { zIndex: 1000, maxWidth: 300, position: "absolute", bottom: 10, left: 10, backgroundColor: "white", borderRadius: 15, padding: 15 }]}
					>
						<View style={{ flexDirection: "row" }}>
							<FontAwesome5 size={28} style={{ marginRight: 10 }} name="cookie-bite" />
							<StyledText bold style={{ fontWeight: "700", fontSize: 20, marginBottom: 10 }}>
								Esta web usa cookies
							</StyledText>
						</View>
						<StyledText style={{ fontSize: 12, marginBottom: 5 }}>
							Las cookies de este sitio web se usan para personalizar el contenido y los anuncios, ofrecer funciones de redes sociales y analizar el tráfico. Además, compartimos
							información sobre el uso que haga del sitio web con nuestros partners de redes sociales, publicidad y análisis web, quienes pueden combinarla con otra información que
							les haya proporcionado o que hayan recopilado a partir del uso que haya hecho de sus servicios. Usted acepta nuestras cookies si continúa utilizando nuestro sitio
							web.
						</StyledText>
						<TouchableOpacity style={{ marginBottom: 10 }} onPress={() => navigationRef.current.navigate("Page", { id: "narD5dLX0RNF2lvTKW1E" })}>
							<StyledText style={{ color: "blue" }}>Leer Política de Cookies</StyledText>
						</TouchableOpacity>
						<View style={{ flexDirection: "row" }}>
							<TouchableOpacity
								onPress={() => denyCookies()}
								style={{ marginRight: 10, alignSelf: "flex-start", paddingVertical: 10, paddingHorizontal: 15, borderRadius: 7, backgroundColor: "#dadada" }}
							>
								<StyledText bold style={{ color: "white", fontWeight: "700", fontSize: 12 }}>
									Denegar Cookies
								</StyledText>
							</TouchableOpacity>
							<TouchableOpacity
								onPress={() => acceptCookies()}
								style={{ alignSelf: "flex-start", paddingVertical: 10, paddingHorizontal: 15, borderRadius: 7, backgroundColor: "#ffc148" }}
							>
								<StyledText bold style={{ color: "white", fontWeight: "700", fontSize: 12 }}>
									Permitir cookies
								</StyledText>
							</TouchableOpacity>
						</View>
					</Animatable.View>
				)}
			</SafeAreaProvider>
		);
	}
}

const styles = StyleSheet.create({
	shadow: {
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 4,
		},
		shadowOpacity: 0.2,
		shadowRadius: 8,

		elevation: 10,
	},
});
