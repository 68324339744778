import React from "react";
import { createStackNavigator } from "@react-navigation/stack";
import ProfileScreen from "../screens/ProfileScreen";

import Header from "../components/Header";
import { useNavigationState } from "@react-navigation/native";

const Stack = createStackNavigator();

const ProfileStack = () => {
	const index = useNavigationState((state) => state.index);
	return (
		<Stack.Navigator
			headerMode="none"
			// screenOptions={{
			// 	header: () => <Header index={index} />,
			// }}
		>
			<Stack.Screen name="ProfileScreen" component={ProfileScreen} />
		</Stack.Navigator>
	);
};

export default ProfileStack;
