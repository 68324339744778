import React, { useState, useEffect, useContext } from "react";
import { Image, View, StyleSheet, ScrollView, TouchableOpacity, TextInput, Platform, Dimensions } from "react-native";
import { Ionicons, Feather, MaterialCommunityIcons } from "@expo/vector-icons";
import { StyledText } from "../components/StyledText";

import ProductVerticalCard from "../components/ProductVerticalCard";
import CategoryLineComponent from "../components/CategoryLineComponent";
import firebase from "firebase/app";
import { uniq, groupBy } from "lodash";
import { StackActions, useLinkTo } from "@react-navigation/native";
import ButtonsStore from "../components/ButtonsStore";
import NewsVerticalCard from "../components/ProductVerticalCard";
import ButtonsAssociation from "../components/ButtonsAssociation";

let Helmet;

if (Platform.OS === "web") {
	Helmet = require("react-helmet").default;
}

const SW = Dimensions.get("screen").width;
const MicrositeScreen = ({ route, navigation }) => {
	const id = route.params?.id;

	const [loading, setLoading] = useState(true);
	const [data, setData] = useState([]);
	const [filtered, setFiltered] = useState([]);
	const [categories, setCategories] = useState([]);
	const [categorySelected, setCategorySelected] = useState(null)
	const [store, setStore] = useState({});
	const [gallerySize, setGallerySize] = useState(600);
	const [search, setSearch] = useState("");

	useEffect(() => {
		id && fetchData();
	}, [id]);

	const fetchData = async () => {
		firebase
			.firestore()
			.collection("associations")
			.doc(id)
			.get()
			.then((doc) => {
				setStore({ ...doc.data(), id: doc.id });
				setLoading(false);
			})
			.catch((err) => {
				setLoading(false);
			});
		firebase
			.firestore()
			.collection("contents")
			.where("association", "==", id)
			.where("type", "==", "ARTICLE")
			.where("status", "==", true)
			.get()
			.then((querySnapShot) => {
				const prod = querySnapShot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
				setData(prod);
				setFiltered(prod);
			});
	};

	useEffect(() => {
		const d = data.filter((el) => el.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()));
		setFiltered(d);
	}, [search]);


	return (
		<View style={{ flex: 1 }}>
			{Platform.OS === "web" && (
				<Helmet>
					<meta charSet="utf-8" />
					<title>{store.name ? `Productos de ${store.name} - Retail Hub Madrid` : "Retail Hub Madrid"}</title>
					<link rel="canonical" href="http://mysite.com/example" />
				</Helmet>
			)}
			<ScrollView style={{ backgroundColor: "white", height: 150 }} contentContainerStyle={{ alignItems: "center" }}>
				<View style={styles.headerContainer}>
					<View style={styles.headerOverlay} />
					<Image style={styles.headerBackground} source={{ uri: store.images && store.images[0] ? store.images[0] : "https://oij.org/wp-content/uploads/2016/05/placeholder.png" }} />
					<StyledText style={styles.title}>{store.name}</StyledText>
				</View>
				
				<ButtonsAssociation id={id} index={1}/>

				<View style={styles.searchSection}>
					<View style={[styles.shadow, styles.searchBar]}>
						<TextInput onChangeText={(ev) => setSearch(ev)} value={search} placeholder="Buscar un artículo..." style={{ width: "100%", zIndex: 2, paddingHorizontal: 15, paddingRight: 40 }} />
						<TouchableOpacity onPress={() => console.log("ASDASD")} style={styles.searchButton}>
							<Ionicons size={25} name="search" />
						</TouchableOpacity>
					</View>
				</View>

				<View style={{ maxWidth: 900, width: "100%", flexDirection: "row" }}>
					<View style={styles.productsContainer}>
						{filtered.length === 0 ? (
							<View style={{ borderRadius: 15, opacity: 0.4, backgroundColor: "gainsboro", width: "100%", height: 300, alignItems: "center", justifyContent: "center" }}>
								<StyledText style={{ fontSize: 18 }}>No hay Artículos</StyledText>
							</View>
						) : (
							filtered.map((news, i) => (
								<NewsVerticalCard
									key={i}
									to={"/article/" + news.id}
									action={StackActions.push('ArticleScreen', { id: news.id })}
									width={"98%"}
									index={i}
									title={news.title}
									image={news.image ? news.image : "https://cdn.shopify.com/s/files/1/0533/2089/files/placeholder-images-product-2_large.png?format=jpg&quality=90&v=1530129318"}
								/>
							))
						)}
					</View>
				</View>
			</ScrollView>
		</View>
	);
};

export default MicrositeScreen;

const styles = StyleSheet.create({
	headerContainer: {
		height: 100,
		width: "100%",
		borderBottomLeftRadius: 10,
		borderBottomRightRadius: 10,
		overflow: "hidden",
		alignItems: "center",
		justifyContent: "center",
	},
	headerOverlay: {
		flex: 1,
		position: "absolute",
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
		backgroundColor: "rgba(0,0,0,0.5)",
		zIndex: 2,
	},
	headerBackground: {
		resizeMode: "cover",
		flex: 1,
		position: "absolute",
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
		zIndex: 1,
	},
	
	bodyContainer: {
		padding: 15,
	},
	title: {
		fontSize: 24,
		fontWeight: "700",
		color: "white",
		zIndex: 4,
		marginBottom: 15,
	},
	searchSection: {
		backgroundColor: "#f3f3f3",
		width: "100%",
		paddingHorizontal: 15,
		paddingTop: 50,
		marginTop: -25,
		paddingBottom: 25,
		alignItems: "center",
		zIndex: 1,
	},
	searchBar: {
		height: 45,
		flexDirection: "row",
		backgroundColor: "white",
		borderRadius: 22.5,
		position: "relative",
		maxWidth: 600,
		width: "100%",
	},
	searchButton: {
		width: 45,
		height: 45,
		alignItems: "center",
		justifyContent: "center",
		position: "absolute",
		right: 0,
		top: 0,
		zIndex: 10,
	},
	body: {
		marginTop: 15,
	},
	tabSelector: {
		paddingTop: 20,
		width: "100%",
		alignItems: "center",
		backgroundColor: "#f3f3f3",
	},
	tab: {
		borderBottomWidth: 0,
		flex: 1,
		height: 45,
		alignItems: "center",
		justifyContent: "center",
		borderBottomColor: "#FFC148",
	},
	tabSelected: {
		borderBottomWidth: 4,
		flex: 1,
		borderBottomColor: "#FFC148",
	},
	tabText: {
		fontSize: 18,
		fontWeight: "700",
		opacity: 0.2,
	},
	tabTextSelected: {
		opacity: 1,
		color: "#FFC148",
	},
	productsContainer: {
		flexDirection: "row",
		flexWrap: "wrap",
		padding: 10,
		width: "100%",
		alignItems:"center",
		justifyContent:"center"
	},

	shadow: {
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 6,
		},
		shadowOpacity: 0.15,
		shadowRadius: 8.3,

		elevation: 13,
	},
});
