import React from "react";
import { View, TouchableOpacity, Platform } from "react-native";
import { useLinkProps } from "@react-navigation/native";
import { useEffect } from "react/cjs/react.development";

const LinkButton = ({ to = null, action, zoom = true, opacity = true, children, ...rest }) => {
	const { onPress, ...props } = useLinkProps({ to, action });

	const [isHovered, setIsHovered] = React.useState(false);

	if (Platform.OS === "web") {
		// It's important to use a `View` or `Text` on web instead of `TouchableX`
		// Otherwise React Native for Web omits the `onClick` prop that's passed
		// You'll also need to pass `onPress` as `onClick` to the `View`
		// You can add hover effects using `onMouseEnter` and `onMouseLeave`
		return (
			<View onClick={onPress} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)} style={{ transitionDuration: "150ms", transform: [{ scale: isHovered && zoom ? 1.05 : 1 }], opacity: isHovered && opacity ? 0.8 : 1 }} {...props} {...rest}>
				{children}
			</View>
		);
	}

	return (
		<TouchableOpacity onPress={onPress} {...props} {...rest}>
			{children}
		</TouchableOpacity>
	);
};

export default LinkButton;
