import React, { useState, useEffect } from "react";
import { Image, View, TouchableOpacity, StyleSheet, Dimensions, Linking, Platform } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import { Ionicons } from "@expo/vector-icons";
import { StyledText } from "../components/StyledText";
import firebase from "firebase/app";
import HTML from "react-native-render-html";
import { Helmet } from "react-helmet";

const SW = Dimensions.get("screen").width;
const SH = Dimensions.get("screen").height;

const ArticleScreen = ({ route, navigation }) => {
	const id = route.params?.id;

	const [data, setData] = useState({});
	const [gallerySize, setGallerySize] = useState(600);

	useEffect(() => {
		id && fetchData();
	}, []);

	const fetchData = async () => {
		firebase
			.firestore()
			.collection("contents")
			.doc(id)
			.get()
			.then((doc) => setData({ ...doc.data(), id: doc.id }));
	};

	const handleShare = (href, target) => {
		// WebBrowser.openBrowserAsync(this.props.href)
		// console.log(window.location)
		Linking.canOpenURL(href, target)
			.then((supported) => {
				if (!supported) {
					console.log("Can't handle url: " + href, target);
				} else {
					if (window) {
						return window.open(href, target);
					} else {
						return Linking.openURL(href, target);
					}
				}
			})
			.catch((err) => console.error("An error occurred", err));
	};

	return (
		<View style={{ flex: 1 }}>
			{Platform.OS === "web" && (
				<Helmet>
					<meta charSet="utf-8" />
					<title>{data.title ? `${data.title} - Retail Hub Madrid` : "Retail Hub Madrid"}</title>
					<link rel="canonical" href="http://mysite.com/example" />
				</Helmet>
			)}

			<ScrollView style={{ backgroundColor: "white", height: 150 }} contentContainerStyle={{ alignItems: "center" }}>
				<View style={{ height: SH / 2.5, width: "100%", alignItems:"center", marginBottom: 10 }}>
					{data.image && (
						<Image style={{ resizeMode: "cover", height: SH / 2.5, width: "100%" }} source={{ uri: data.image }} />
					)}
				</View>
				<View style={{ maxWidth: 600, width: "100%" }}>
					<View style={styles.bodyContainer}>
						<StyledText style={styles.title}>{data.title}</StyledText>
						
						<HTML source={{ html: data.content ? data.content : "<p/>" }} computeEmbeddedMaxWidth={() => 600} />

						<StyledText style={styles.heading}>Compartir</StyledText>
						<View style={styles.socialRow}>
							<TouchableOpacity onPress={() => handleShare(`mailto:?subject=${data.title} en Retail Hub Madrid&body=He encontrado ${data.title} en Retail Hub Madrid <a href="${window.location}">${window.location}</a>.`, "_blank")} style={styles.socialIcon}>
								<Ionicons name="mail-open" size={35} />
							</TouchableOpacity>
							<TouchableOpacity onPress={() => handleShare(`https://twitter.com/share?text=${data.title} en Retail Hub Madrid&url=${window.location}&hashtags=RetailHubMadrid`, "_blank")} style={styles.socialIcon}>
								<Ionicons name="logo-twitter" size={35} />
							</TouchableOpacity>
							{/* <TouchableOpacity style={styles.socialIcon}>
								<Ionicons name="logo-instagram" size={35} />
							</TouchableOpacity> */}
							<TouchableOpacity onPress={() => handleShare(`https://wa.me/?text=${data.title} en  Retail Hub Madrid${window.location}`, "_blank")} style={styles.socialIcon}>
								<Ionicons name="logo-whatsapp" size={35} />
							</TouchableOpacity>
						</View>
					</View>
				</View>
			</ScrollView>
		</View>
	);
};

export default ArticleScreen;

const styles = StyleSheet.create({
	headerContainer: {
		height: 200,
		width: "100%",
		borderBottomLeftRadius: 10,
		borderBottomRightRadius: 10,
		overflow: "hidden",
	},
	headerBackground: {
		resizeMode: "cover",
		flex: 1,
		position: "absolute",
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
	},
	buttonsRow: {
		flexDirection: "row",
		paddingHorizontal: 15,
		marginTop: -25,
	},
	button: {
		height: 50,
		width: 50,
		backgroundColor: "white",
		alignItems: "center",
		justifyContent: "center",
		borderRadius: 7,
		marginRight: 10,
	},
	bodyContainer: {
		padding: 15,
	},
	title: {
		fontSize: 24,
		fontWeight: "700",
	},
	store: {
		fontSize: 18,
		color: "#FFC148",
		marginVertical: 5,
	},
	brand: {
		fontSize: 18,
		color: "gray",
	},
	priceBlock: {
		height: 50,
		flexDirection: "row",
		alignSelf: "flex-end",
		backgroundColor: "white",
		marginTop: 30,
		marginBottom: 20,
		borderRadius: 10,
	},
	priceContainer: {
		flex: 1,
		paddingHorizontal: 40,
		alignItems: "center",
		justifyContent: "center",
	},
	price: {
		fontSize: 24,
		fontWeight: "700",
	},
	buyButton: {
		flex: 1,
		paddingHorizontal: 20,
		width:300,
		alignItems: "center",
		justifyContent: "center",
		borderRadius: 7,
		backgroundColor: "#FFC148",
	},
	buttonText: {
		fontSize: 18,
		color: "white",
		fontWeight: "700",
	},
	body: {
		marginTop: 15,
	},
	heading: {
		fontSize: 18,
		marginTop: 15,
	},
	socialRow: {
		flexDirection: "row",
		marginTop: 15,
	},
	socialIcon: {
		marginRight: 10,
		height: 40,
		width: 40,
		alignItems: "center",
		justifyContent: "center",
	},
	galleryContainer: {
		backgroundColor: "#F3F3F3",
		paddingHorizontal: 5,
		paddingBottom: 15,
	},
	gallery: {
		flexDirection: "row",
		flexWrap: "wrap",
	},
	galleryItem: {
		width: (SW - 50) / 3,
		height: (SW - 50) / 3,
		margin: 5,
		borderRadius: 10,
		resizeMode: "cover",
	},
	miniMapContainer: {
		height: 220,
		borderRadius: 10,
		overflow: "hidden",
		width: "100%",
		backgroundColor: "white",
	},
	shadow: {
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 6,
		},
		shadowOpacity: 0.15,
		shadowRadius: 8.3,

		elevation: 13,
	},
});
