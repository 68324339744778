import React, { useRef, useState } from "react";
// import MapView, { Circle, Marker } from "react-native-maps";
import {
	StyleSheet,
	Text,
	Image,
	View,
	ScrollView,
	FlatList,
	TouchableOpacity,
	Platform,
	TextInput,
	Dimensions,
	ActivityIndicator,
	Modal,
	Touchable,
	Pressable,
} from "react-native";
import { Ionicons, MaterialCommunityIcons, Feather } from "@expo/vector-icons";
import DrawerLayout from "react-native-gesture-handler/DrawerLayout";
import RNPickerSelect from "react-native-picker-select";
import Slider from "@react-native-community/slider";

import * as Animatable from "react-native-animatable";
import { useEffect } from "react";

import algoliasearch from "algoliasearch/lite";
import { useNavigation } from "@react-navigation/native";
import { StyledText } from "../components/StyledText";

import firebase, { firestore } from "firebase/app";
import { Helmet } from "react-helmet";
import LinkButton from "../components/LinkButton";
import Header from "../components/Header";
import HTML from "react-native-render-html";
import Footer from "../components/Footer";
import GrowingElement from "../components/GrowingElement";
import DownloadBlock from "../components/DownloadBlock";
import { TouchableHighlight, TouchableWithoutFeedback } from "react-native-gesture-handler";

const client = algoliasearch("FPUF7P23Y7", "4096d8d6f649f05502ca9e678e4c677a");
const index = client.initIndex("stores");

const SW = Dimensions.get("window").width;
const SH = Dimensions.get("window").height;

const RegisterScreen = ({ route }) => {
	const navigation = useNavigation();

	const scrollRef = useRef();

	const [claimBusiness, setClaimBusiness] = useState(false);
	const [data, setData] = useState({});
	const [success, setSuccess] = useState(false);
	const [loading, setLoading] = useState(false);
	const [stores, setStores] = useState([]);
	const [showStoresModal, setShowStoresModal] = useState(false);
	const [error, setError] = useState(false);
	const [shouldAccept, setShouldAccept] = useState(false);
	const [userExists, setUserExists] = useState(false);
	const [fillForms, setFillForms] = useState(false);
	const [form, setForm] = useState({
		acceptPrivacyPolicy: false,
		acceptServiceAgreement: false,
		name: "",
		surname: "",
		phone: "",
		web: "",
		email: "",
		store: "",
		address: "",
	});

	const [formType, setFormType] = useState(0);

	useEffect(() => {
		// fetch();
	}, []);

	const fetchData = async () => {
		id &&
			firebase
				.firestore()
				.collection("pages")
				.doc(id)
				.get()
				.then((doc) => {
					setData({ ...doc.data(), id: doc.id });
				})
				.catch((err) => console.log(err));
	};

	const searchStores = async () => {
		// console.log("CLICK");
		index.search(form.store, { filters: "_tags:not_claimed" }).then(({ hits }) => {
			// console.log(hits);
			setStores(hits);
			setShowStoresModal(true);
		});
	};


	const submitForm = async () => {
		setError(false);
		setShouldAccept(false);
		setFillForms(false);
		setUserExists(false);
		setLoading(true);
		if (form.surname === "" || form.phone === "" || form.email === "" || form.store === "" || form.name === "" || form.address === "") {
			setFillForms(true);
			setLoading(false);
			
		} else {
			if (form.acceptPrivacyPolicy && form.acceptServiceAgreement) {
				fetch("https://europe-west1-retailhub-6602d.cloudfunctions.net/checkIfUserExists", {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({ email: form.email }),
				})
					.then((res) => res.json())
					.then((res) => {
						console.log(res);
						if (!res) {
							firestore()
								.collection("requests")
								.add(form)
								.then((res) => {
									setLoading(false);
									setSuccess(true);
								})
								.catch((err) => {
									console.log(err);
									setLoading(false);
									setError(true);
								});
						} else {
							setUserExists(true);
							setLoading(false);
						}
					})
					.catch((err) => {
						console.log(err);
						setError(true);
						setLoading(false);
					});
			} else {
				setShouldAccept(true);
				setLoading(false);
			}
		}
	};

	return (
		<>
			<ScrollView ref={scrollRef} style={styles.container} contentContainerStyle={{ flexGrow: 1 }}>
				{Platform.OS === "web" && (
					<Helmet>
						<meta charSet="utf-8" />
						<title>{"Participa en el Retail Hub Madrid - El comercio de Proximidad de Madrid"}</title>
						<link rel="canonical" href="https://reatilhubmadrid.web.app/home" />
					</Helmet>
				)}
				

				<View style={{ width: "100%", paddingVertical: 100, height: 500, alignItems: "center" }}>
					<Image style={{ position: "absolute", top: 0, left: 0, bottom: 0, right: 0, zIndex: 1, resizeMode: "cover", height: 500, }} source={require("../assets/bgduotone.jpg")} />
					<View style={{ position: "absolute", top: 0, left: 0, bottom: 0, right: 0, zIndex: 2, backgroundColor: "rgba(10,10,10,.6)" }} />
					<Animatable.View animation="fadeInUp" style={{ zIndex: 3, paddingHorizontal: 10, alignItems: "center", maxWidth: 600, justifyContent: "center", flex: 1 }}>
						<StyledText style={{ color: "white", fontSize: 44, fontWeight: "700", textAlign: "center" }}>Únete a RetailHub y Participa</StyledText>
						<View style={{ maxWidth: 600, alignSelf: "center", alignItems: "center" }}>
							<StyledText style={{ marginBottom: 0, fontSize: 18, color: "white", marginTop: 20, textAlign: "center" }}>
								Queremos que tengas a tu disposición toda la información del comercio de Madrid. Ya seas un comerciante y buscas tener un sitio donde mostrar tus fantásticos
								productos o seas un intrepido comprador buscando los últimos productos disponibles más cerca de ti!
							</StyledText>
						</View>
					</Animatable.View>
				</View>

				<View style={{ flex: 1, backgroundColor: "white", alignItems: "center", paddingVertical: 80 }}>
					<StyledText style={{ fontSize: 34, marginBottom: 10, fontWeight: "700", textAlign: "center" }}>¿Qué vas encontrar?</StyledText>
					<View
						style={{
							flexDirection: "row",
							flexWrap: "wrap",
							maxWidth: 1024,
							width: "100%",
							alignSelf: "center",
							justifyContent: "center",
							alignItems: "center",
							paddingVertical: 50,
						}}
					>
						<GrowingElement opacity={false} style={{ minWidth: 200, flex: 1, alignItems: "center", marginHorizontal: 15 }}>
							<Image source={require("../assets/mapicon.png")} style={{ height: 200, width: "100%", resizeMode: "contain" }} />
							<StyledText style={{ marginBottom: 0, fontSize: 24, fontWeight: "700", textAlign: "center" }}>+33.000 Comercios</StyledText>
							<StyledText style={{ marginBottom: 0, fontSize: 16, marginTop: 10, textAlign: "center" }}>Todos los comercios de Madrid a tu disposición</StyledText>
						</GrowingElement>
						<GrowingElement opacity={false} style={{ minWidth: 200, flex: 1, alignItems: "center", marginHorizontal: 15 }}>
							<Image source={require("../assets/presents.png")} style={{ height: 200, width: "100%", resizeMode: "contain" }} />
							<StyledText style={{ marginBottom: 0, fontSize: 24, fontWeight: "700", textAlign: "center" }}>Microsites y Productos</StyledText>
							<StyledText style={{ marginBottom: 0, fontSize: 16, marginTop: 10, textAlign: "center" }}>Presenta y descubre productos y servicios</StyledText>
						</GrowingElement>
						<GrowingElement opacity={false} style={{ minWidth: 200, flex: 1, alignItems: "center", marginHorizontal: 15 }}>
							<Image source={require("../assets/walleticon.png")} style={{ height: 200, width: "100%", resizeMode: "contain" }} />
							<StyledText style={{ marginBottom: 0, fontSize: 24, fontWeight: "700", textAlign: "center" }}>Promociones Exclusivas</StyledText>
							<StyledText style={{ marginBottom: 0, fontSize: 16, marginTop: 10, textAlign: "center" }}>Promociones exclusivas para comerciantes y consumidores</StyledText>
						</GrowingElement>
						<GrowingElement opacity={false} style={{ minWidth: 200, flex: 1, alignItems: "center", marginHorizontal: 15 }}>
							<Image source={require("../assets/device.png")} style={{ height: 200, width: "100%", resizeMode: "contain" }} />
							<StyledText style={{ marginBottom: 0, fontSize: 24, fontWeight: "700", textAlign: "center" }}>En cualquier dispositivo</StyledText>
							<StyledText style={{ marginBottom: 0, fontSize: 16, marginTop: 10, textAlign: "center" }}>Descarga la aplicación o navega en la web</StyledText>
						</GrowingElement>
					</View>
				</View>

				{/* REGISTER FORM */}
				<View style={{ backgroundColor: "#f3f3f3", width: "100%", marginTop: 50, paddingVertical: 20, paddingBottom: 80, paddingHorizontal: 20, alignItems: "center" }}>
					<View style={{ width: "100%", maxWidth: 600, alignItems: "center" }}>
						<StyledText style={{ fontSize: 34, marginBottom: 15, fontWeight: "700", textAlign: "center" }}>Regístrate para comenzar</StyledText>

						<Animatable.View animation="fadeInUp" style={{ padding: 15, borderRadius: 10, marginTop: 15, backgroundColor: "white", marginBottom: 15 }}>
							{success ? (
								<View style={{ flexDirection: "row" }}>
									<StyledText style={{ marginBottom: 0, fontSize: 60, marginRight: 20, textAlign: "center" }}>🎉</StyledText>
									<StyledText style={{ marginBottom: 0, fontSize: 16 }}>
										Enhorabuena {form.name}! Hemos recibido tu solicitud con éxito y estamos procesándola. Nos pondremos encontacto contigo en el correo {form.email} para
										facilitarte tus datos de acceso al Club del Comercio a través del email que has facilitado.
									</StyledText>
								</View>
							) : (
								<StyledText style={{ marginBottom: 0, fontSize: 16, textAlign: "center" }}>
									Una vez completes el formulario validaremos tu solicitud y te enviaremos información para poder acceder al area de gestión de RetailHub
								</StyledText>
							)}
						</Animatable.View>
						{!success && (
							<Animatable.View animation="fadeInUp" delay={300} style={{ flexGrow: 1, minHeight: 720, marginTop: 15, width: "100%" }}>
								<View style={{ flexDirection: SW < 800 ? "column" : "row" }}>
									<View style={{ flex: 1 }}>
										<StyledText style={{ fontSize: 14, marginTop: 15, marginBottom: 5 }}>Nombre</StyledText>
										<View style={[styles.shadow, { backgroundColor: "white", height: 45, borderRadius: 25, width: "100%" }]}>
											<TextInput
												value={form.name}
												placeholder={`Nombre`}
												onChangeText={(ev) => setForm({ ...form, name: ev })}
												style={{ paddingHorizontal: 15, height: 45, fontSize: 16, flex: 1, width: "100%" }}
											/>
										</View>
									</View>
									<View style={{ marginLeft: SW < 800 ? 0 : 15, marginTop: 15, flex: 1 }}>
										<StyledText style={{ fontSize: 14, marginBottom: 5 }}>Apellidos</StyledText>
										<View style={[styles.shadow, { backgroundColor: "white", height: 45, borderRadius: 25, width: "100%" }]}>
											<TextInput
												value={form.surname}
												placeholder={`Apellidos`}
												onChangeText={(ev) => setForm({ ...form, surname: ev })}
												style={{ paddingHorizontal: 15, height: 45, fontSize: 16, flex: 1, width: "100%" }}
											/>
										</View>
									</View>
								</View>
								<View style={{ marginTop: 15 }}>
									<StyledText style={{ fontSize: 14, marginBottom: 5 }}>Email de contacto</StyledText>
									<View style={[styles.shadow, { backgroundColor: "white", height: 45, borderRadius: 25, width: "100%" }]}>
										<TextInput
											value={form.email}
											placeholder={`Email`}
											keyboardType="email-address"
											onChangeText={(ev) => {
												setUserExists(false);
												setForm({ ...form, email: ev })
											}}
											style={{ paddingHorizontal: 15, fontSize: 16, height: 45, flex: 1, width: "100%" }}
										/>
									</View>
								</View>
								{formType === 1 && (
									<View style={{ flex: 1, marginTop: 15 }}>
										<StyledText style={{ fontSize: 14, marginBottom: 5 }}>Teléfono de contacto</StyledText>
										<View style={[styles.shadow, { backgroundColor: "white", height: 45, borderRadius: 25, width: "100%" }]}>
											<TextInput
												value={form.phone}
												placeholder={`Teléfono`}
												keyboardType="name-phone-pad"
												dataDetectorTypes="phoneNumber"
												onChangeText={(ev) => setForm({ ...form, phone: ev })}
												style={{ paddingHorizontal: 15, fontSize: 16, height: 45, flex: 1, width: "100%" }}
											/>
										</View>
									</View>
								)}

								{formType === 0 && (
									<>
										<View style={{ flexDirection: SW < 800 ? "column" : "row" }}>
											<View style={{ flex: 1, marginTop: 15 }}>
												<StyledText style={{ fontSize: 14, marginBottom: 5 }}>Teléfono de contacto</StyledText>
												<View style={[styles.shadow, { backgroundColor: "white", height: 45, borderRadius: 25, width: "100%" }]}>
													<TextInput
														value={form.phone}
														placeholder={`Teléfono`}
														keyboardType="name-phone-pad"
														dataDetectorTypes="phoneNumber"
														onChangeText={(ev) => setForm({ ...form, phone: ev })}
														style={{ paddingHorizontal: 15, fontSize: 16, height: 45, flex: 1, width: "100%" }}
													/>
												</View>
											</View>
											<View style={{ flex: 1, marginTop: 15, marginLeft: SW < 800 ? 0 : 15 }}>
												<StyledText style={{ fontSize: 14, marginBottom: 5 }}>Web</StyledText>
												<View style={[styles.shadow, { backgroundColor: "white", height: 45, borderRadius: 25, width: "100%" }]}>
													<TextInput
														value={form.web}
														placeholder={`Sitio Web`}
														dataDetectorTypes="link"
														keyboardType="url"
														onChangeText={(ev) => setForm({ ...form, web: ev })}
														style={{ paddingHorizontal: 15, fontSize: 16, height: 45, flex: 1, width: "100%" }}
													/>
												</View>
											</View>
										</View>
										<View style={{ marginTop: 15 }}>
											<StyledText style={{ fontSize: 14, marginBottom: 5 }}>Nombre de Empresa</StyledText>
											<View style={[styles.shadow, { backgroundColor: "white", height: 45, borderRadius: 25, width: "100%" }]}>
												<TextInput
													value={form.store}
													placeholder={`Nombre de Empresa`}
													onChangeText={(ev) => setForm({ ...form, store: ev })}
													style={{ paddingHorizontal: 15, fontSize: 16, height: 45, flex: 1, width: "100%" }}
												/>
											</View>
											{/* <TouchableOpacity onPress={() => searchStores()} style={{ flexDirection: "row", alignItems: "center", marginTop: 10 }}>
											<Ionicons name="search" size={20} style={{ marginRight: 5 }} />
											<StyledText>Buscar Establecimiento</StyledText>
										</TouchableOpacity> */}
										</View>
										<View style={{ marginTop: 15 }}>
											<StyledText style={{ fontSize: 14, marginBottom: 5 }}>Dirección del Establecimiento</StyledText>
											<View style={[styles.shadow, { backgroundColor: "white", height: 45, borderRadius: 25, width: "100%" }]}>
												<TextInput
													value={form.address}
													placeholder={`Dirección  del Establecimiento`}
													dataDetectorTypes="address"
													onChangeText={(ev) => setForm({ ...form, address: ev })}
													style={{ paddingHorizontal: 15, fontSize: 16, height: 45, flex: 1, width: "100%" }}
												/>
											</View>
											<StyledText style={{ fontSize: 12, opacity: 0.5, marginTop: 10 }}>Si eres el propietario de varios establecimientos</StyledText>
											<StyledText style={{ fontSize: 12, opacity: 0.5 }}>podrás agregarlos desde el panel de gestión una vez verificado.</StyledText>
										</View>
									</>
								)}

								<View style={{ marginTop: 15, width: "100%" }}>
									<TouchableOpacity
										style={{ marginTop: 10, flexDirection: "row", alignItems: "center" }}
										onPress={() => setForm({ ...form, acceptPrivacyPolicy: !form.acceptPrivacyPolicy })}
									>
										<View
											style={{ alignItems: "center", justifyContent: "center", width: 30, marginRight: 10, height: 30, borderRadius: 6, borderColor: "#FFC148", borderWidth: 3 }}
										>
											<View style={{ width: 18, height: 18, borderRadius: 3, backgroundColor: form.acceptPrivacyPolicy ? "#FFC148" : "white" }} />
										</View>
										<View style={{ flex: 1 }}>
											<StyledText style={{ fontSize: 16 }}>
												Acepto la Política de Privacidad de RetailHub <StyledText style={{ fontSize: 10, color: "gray" }}>(Obligatorio)</StyledText>
											</StyledText>
										</View>
									</TouchableOpacity>
								</View>
								<View style={{ marginTop: 15, width: "100%" }}>
									<TouchableOpacity
										style={{ marginTop: 10, flexDirection: "row", alignItems: "center" }}
										onPress={() => setForm({ ...form, acceptServiceAgreement: !form.acceptServiceAgreement })}
									>
										<View
											style={{ alignItems: "center", justifyContent: "center", width: 30, marginRight: 10, height: 30, borderRadius: 6, borderColor: "#FFC148", borderWidth: 3 }}
										>
											<View style={{ width: 18, height: 18, borderRadius: 3, backgroundColor: form.acceptServiceAgreement ? "#FFC148" : "white" }} />
										</View>
										<View style={{ flex: 1 }}>
											<StyledText style={{ fontSize: 16 }}>
												Acepto la Política de Servicio de RetailHub <StyledText style={{ fontSize: 10, color: "gray" }}>(Obligatorio)</StyledText>
											</StyledText>
										</View>
									</TouchableOpacity>
								</View>
								<View style={{ marginTop: 15, width: "100%" }}>
									<TouchableOpacity
										style={{ marginTop: 10, flexDirection: "row", alignItems: "center" }}
										onPress={() => setForm({ ...form, acceptNotifications: !form.acceptNotifications })}
									>
										<View
											style={{ alignItems: "center", justifyContent: "center", width: 30, marginRight: 10, height: 30, borderRadius: 6, borderColor: "#FFC148", borderWidth: 3 }}
										>
											<View style={{ width: 18, height: 18, borderRadius: 3, backgroundColor: form.acceptNotifications ? "#FFC148" : "white" }} />
										</View>
										<View style={{ flex: 1 }}>
											<StyledText style={{ fontSize: 16 }}>Deseo recibir ofertas y promociones</StyledText>
										</View>
									</TouchableOpacity>
								</View>

								{userExists && (
									<Animatable.View animation="fadeInUp" style={{ padding: 15, borderRadius: 10, marginTop: 15, backgroundColor: "white", marginBottom: 15 }}>
										<View style={{ flexDirection: "row", alignItems: "center" }}>
											<StyledText style={{ marginBottom: 0, fontSize: 40, marginRight: 20, textAlign: "center" }}>👀</StyledText>
											<StyledText style={{ marginBottom: 0, fontSize: 16 }}>
												El email {form.email} ya se encuentra registrado en la plataforma. Recupera tu contraseña o emplea otro email.
											</StyledText>
										</View>
									</Animatable.View>
								)}

								{shouldAccept && (
									<Animatable.View animation="fadeInUp" style={{ padding: 15, borderRadius: 10, marginTop: 15, backgroundColor: "white", marginBottom: 15 }}>
										<View style={{ flexDirection: "row", alignItems: "center" }}>
											<StyledText style={{ marginBottom: 0, fontSize: 40, marginRight: 20, textAlign: "center" }}>✋</StyledText>
											<StyledText style={{ marginBottom: 0, fontSize: 16 }}>
												Debes aceptar las Políticas de Privacidad y las Condiciones de uso de Retail Hub para continuar
											</StyledText>
										</View>
									</Animatable.View>
								)}

								{fillForms && (
									<Animatable.View animation="fadeInUp" style={{ padding: 15, borderRadius: 10, marginTop: 15, backgroundColor: "white", marginBottom: 15 }}>
										<View style={{ flexDirection: "row", alignItems: "center" }}>
											<StyledText style={{ marginBottom: 0, fontSize: 40, marginRight: 20, textAlign: "center" }}>✋</StyledText>
											<StyledText style={{ marginBottom: 0, fontSize: 16 }}>Debes completar todos los campos para poder enviar el formulario</StyledText>
										</View>
									</Animatable.View>
								)}

								{error && (
									<Animatable.View animation="fadeInUp" style={{ padding: 15, borderRadius: 10, marginTop: 15, backgroundColor: "#EC3535", marginBottom: 15 }}>
										<View style={{ flexDirection: "row", alignItems: "center" }}>
											<StyledText style={{ marginBottom: 0, fontSize: 40, marginRight: 20, textAlign: "center" }}>🤔</StyledText>
											<StyledText style={{ marginBottom: 0, fontSize: 16, color: "white", fontWeight: "700	" }}>
												Ha ocurrido un error a la hora de entregar tu formulario, por favor inténtalo de nuevo mas tarde
											</StyledText>
										</View>
									</Animatable.View>
								)}

								<View style={{ width: "100%", alignItems: "flex-end" }}>
									<GrowingElement
										opacity={false}
										onPress={() => {
											submitForm();
										}}
									>
										<View
											style={[
												styles.shadow,
												{
													backgroundColor: "#ffc148",
													flexDirection: "row",
													alignItems: "center",
													justifyContent: "center",
													paddingHorizontal: 15,
													height: 50,
													marginTop: 15,
													borderRadius: 25,
													justifyContent: "center",
												},
											]}
										>
											{loading ? (
												<ActivityIndicator color="black" />
											) : (
												<StyledText style={{ marginHorizontal: 15, color: "black", fontSize: 16, textAlign: "center", fontWeight: "700" }}>Registrarme</StyledText>
											)}
										</View>
									</GrowingElement>
								</View>
							</Animatable.View>
						)}
					</View>
				</View>
				{/* ENDREGISTER FORM */}

				<DownloadBlock />
				<Footer />
			</ScrollView>

			{showStoresModal && (
				<Modal
					visible={showStoresModal}
					presentationStyle="overFullScreen"
					hasBackdrop={true}
					coverScreen={true}
					style={{ position: "fixed", zIndex: 30, height: SH, width: SW, alignItems: "center", justifyContent: "center", borderColor: "transparent", borderWidth: 0 }}
				>
					<Pressable onPress={() => setShowStoresModal(false)} style={{ position: "absolute", width: "100%", height: "100%", zIndex: 1 }}>
						<View style={{ backgroundColor: "rgba(0,0,0,.8)", flex: 1 }} />
					</Pressable>

					<View style={{ position: "relative", zIndex: 2, padding: 15, borderRadius: 10, backgroundColor: "white", maxHeight: 600 }}>
						<View style={{ flexDirection: "row", alignItems: "center" }}>
							<View style={{ flex: 1 }}>
								<StyledText style={{ fontSize: 24, marginBottom: 10, fontWeight: "700", textAlign: "center" }}>Selecciona tu negocio para reclamar su titularidad</StyledText>
							</View>
						</View>
						<View style={{ height: 40, borderRadius: 25, margin: 10, borderWidth: 1 }}>
							<TextInput style={{ paddingHorizontal: 10, flex: 1 }} placeholder="Nombre y dirección..." />
						</View>
						<ScrollView>
							<View style={{ padding: 15, alignItems: "center", maxWidth: 450, width: "100%", marginHorizontal: 10 }}>
								{stores.length === 0 ? (
									<StyledText>No hemos encontrado ninguna tienda con esta busqueda</StyledText>
								) : (
									stores.map((store, i) => {
										return (
											<View key={i} style={{ paddingHorizontal: 10, paddingVertical: 10, width: "100%" }}>
												<StyledText style={{ fontSize: 16, fontWeight: "700" }} key={i}>
													{store.name}
												</StyledText>
												<StyledText style={{ fontSize: 12 }} key={i}>
													{store.fullAddress}
												</StyledText>
											</View>
										);
									})
								)}
							</View>
						</ScrollView>
					</View>
				</Modal>
			)}
		</>
	);
};

export default RegisterScreen;

const styles = StyleSheet.create({
	container: {
		flex: 1,
		zIndex: 1,
		position: "relative",
	},
	header: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		paddingLeft: 15,
		paddingRight: 5,
		paddingBottom: 30,
		backgroundColor: "#FFC148",
		width: "100%",
	},
	logo: {
		height: 40,
		width: 150,
		marginBottom: 5,
	},
	searchBackground: {
		height: 25,
		backgroundColor: "#FFC148",
	},
	searchContainer: {
		flexDirection: "row",
		justifyContent: "space-between",
		height: 50,
		marginBottom: 0,
		marginTop: -25,
		zIndex: 10,
	},
	searchBar: {
		width: "100%",
		height: 50,
		maxWidth: 600,
		marginTop: 20,
		borderRadius: 25,
		backgroundColor: "white",
	},
	searchFilterButton: {
		width: 60,
		height: 50,
		justifyContent: "center",
		borderTopLeftRadius: 25,
		borderBottomLeftRadius: 25,
		paddingLeft: 15,
		backgroundColor: "white",
	},
	map: {
		zIndex: 1,
		flex: 1,
		marginTop: -25,
		height: "100%",
		width: "100%",
	},

	shadow: {
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 6,
		},
		shadowOpacity: 0.15,
		shadowRadius: 8.3,

		elevation: 13,
	},

	sideShadow: {
		shadowColor: "#000",
		shadowOffset: {
			width: 5,
			height: 0,
		},
		shadowOpacity: 0.15,
		shadowRadius: 10,

		elevation: 13,
	},
});
