import React, { useRef, useState } from "react";
// import MapView, { Circle, Marker } from "react-native-maps";
import { StyleSheet, Text, Image, View, ScrollView, FlatList, TouchableOpacity, Platform, TextInput, Dimensions, ActivityIndicator, Alert } from "react-native";
import { Ionicons, MaterialCommunityIcons, Feather } from "@expo/vector-icons";
import DrawerLayout from "react-native-gesture-handler/DrawerLayout";
import RNPickerSelect from "react-native-picker-select";
import Slider from "@react-native-community/slider";

import * as Animatable from "react-native-animatable";
import { useEffect } from "react";

import algoliasearch from "algoliasearch/lite";
import { StackActions, useNavigation } from "@react-navigation/native";
import { StyledText } from "../components/StyledText";
import Map from "../components/Map";
import StoreCard from "../components/StoreCard";

import firebase from "firebase/app";

import * as Location from "expo-location";
import { Helmet } from "react-helmet";
import LinkButton from "../components/LinkButton";
import Header from "../components/Header";
import Footer from "../components/Footer";
import DownloadBlock from "../components/DownloadBlock";

const SW = Dimensions.get("window").width;
const SH = Dimensions.get("window").height;

const HomeScreen = () => {
	const navigation = useNavigation();

	const [search, setSearch] = useState("");
	const [searchFocus, setSearchFocus] = useState(false);

	const [indexName, setIndexName] = useState("Negocios");

	useEffect(() => {
		// setTimeout(() => {
		// 	indexName === "Negocios" ? setIndexName("Productos") : indexName === "Productos" ? setIndexName("Promociones") : setIndexName("Negocios");
		// }, 2000);
	}, [indexName]);

	return (
		<ScrollView style={styles.container} contentContainerStyle={{ flexGrow: 1 }}>
			{Platform.OS === "web" && (
				<Helmet>
					<meta charSet="utf-8" />
					<title>Retail Hub Madrid - El comercio de Proximidad de Madrid</title>
					<link rel="canonical" href="https://reatilhubmadrid.web.app/home" />
				</Helmet>
			)}
			{/* <Header index={0} /> */}
			<View style={{ width: "100%", paddingVertical: 50, minHeight: Platform.OS === "web" ? "80vh" : SH / 2, alignItems: "center" }}>
				<Image style={{ position: "absolute", top: 0, left: 0, bottom: 0, right: 0, zIndex: 1, resizeMode: "cover" }} source={{ uri: "https://images.pexels.com/photos/6205478/pexels-photo-6205478.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260" }} />
				<View style={{ position: "absolute", top: 0, left: 0, bottom: 0, right: 0, zIndex: 2, backgroundColor: "rgba(0,0,0,.5)" }} />
				<Animatable.View animation="fadeInUp" style={{ zIndex: 3, paddingHorizontal: 10, alignItems: "center", maxWidth: 800, justifyContent: "center", flex: 1 }}>
					<StyledText style={{ color: "white", fontSize: 50, marginBottom: 10, fontWeight: "700", textAlign: "center" }}>Bienvenido a RetailHub</StyledText>
					<StyledText style={{ color: "white", fontSize: 22, fontWeight: "700", textAlign: "center" }}>El espacio del comercio de proximidad de Madrid</StyledText>
					<View style={styles.searchContainer}>
						<View style={[styles.searchBar, styles.shadow, { transitionDuration: "150ms", transform: [{ scale: searchFocus ? 1.02 : 1 }] }]}>
							<TextInput
								placeholder={`Escribe qué estás buscando...`}
								onFocus={() => setSearchFocus(true)}
								onBlur={() => setSearchFocus(false)}
								onSubmitEditing={() => navigation.navigate("Search", { screen: "Map", params: { searchQuery: search } })}
								onChangeText={(ev) => setSearch(ev)}
								style={{ fontSize: 16, flex: 1, paddingLeft: 20, paddingRight: 50 }}
							/>
							<TouchableOpacity style={{ position: "absolute", right: 0, height: 50, width: 50, alignItems: "center", justifyContent: "center" }}>
								<Ionicons name="search" size={25} color="#4C4C4C" />
							</TouchableOpacity>
						</View>
					</View>
					<View style={{ flexDirection: SW < 800 ? "column" : "row", alignItems: "center" }}>
						<View style={{ marginRight: SW < 800 ? 0 : 10 }}>
							<LinkButton opacity={false}>
								<TouchableOpacity
									onPress={() => navigation.navigate("Search", { screen: "Map", params: { searchQuery: search, searchType: "stores" } })}
									style={[styles.shadow, { backgroundColor: "#ffc148", flexDirection: "row", alignItems: "center", justifyContent: "center", paddingHorizontal: 15, height: 40, marginTop: 30, borderRadius: 7, justifyContent: "center" }]}>
									<MaterialCommunityIcons name="storefront-outline" size={28} color="white" />
									<StyledText style={{ marginLeft: 10, color: "white", fontSize: 16, textAlign: "center", fontWeight: "700" }}>Buscar Tiendas</StyledText>
								</TouchableOpacity>
							</LinkButton>
						</View>
						<LinkButton opacity={false}>
							<TouchableOpacity
								onPress={() => navigation.navigate("Search", { screen: "Map", params: { searchQuery: search, searchType: "products" } })}
								style={[styles.shadow, { backgroundColor: "#ffc148", flexDirection: "row", alignItems: "center", justifyContent: "center", paddingHorizontal: 15, height: 40, marginTop: 30, borderRadius: 7, justifyContent: "center" }]}>
								<Feather name="shopping-bag" size={25} color="white" />
								<StyledText style={{ marginLeft: 10, color: "white", fontSize: 16, textAlign: "center", fontWeight: "700" }}>Buscar Productos</StyledText>
							</TouchableOpacity>
						</LinkButton>
					</View>
				</Animatable.View>
			</View>
			<View style={{ backgroundColor: "#F2F3F5" }}>
				<Animatable.View animation="fadeInUp" delay={200} style={{ width: "100%", maxWidth: 650, alignSelf: "center", alignItems: "center", paddingVertical: 90, paddingHorizontal: 10 }}>
					<StyledText style={{ fontSize: 44, marginBottom: 10, fontWeight: "700", textAlign: "center" }}>Una nueva forma de relacionarte con el comercio de proximidad. Mas cerca de ti que nunca.</StyledText>
					<TouchableOpacity onPress={() => navigation.navigate("Register")} style={{ marginTop: 20 }}>
						<StyledText style={{ fontSize: 20 }}>
							Descubre cómo <StyledText style={{ fontSize: 30 }}>👉</StyledText>
						</StyledText>
					</TouchableOpacity>
				</Animatable.View>
			</View>

			<Animatable.View animation="fadeInUp" delay={600} style={{ flexDirection: SW < 800 ? "column" : "row", width: "100%", maxWidth: 1080, marginTop: 50, alignSelf: "center", alignItems: "center", paddingHorizontal: 10 }}>
				<View style={{ flex: 1, width: SW < 800 ? "100%" : null }}>
					<Image style={{ borderRadius: 15, flex: 1, height: 400, resizeMode: "contain", width: "100%" }} source={require("../assets/manphone.png")} />
				</View>
				<View style={{ flex: 1, marginHorizontal: 10 }}>
					<StyledText style={{ fontSize: 34, marginBottom: 15, fontWeight: "700" }}>Comercio, Personas, Tiendas y Servicios</StyledText>
					<StyledText style={{ fontSize: 18 }}>
						Nos orientamos al cliente en todo momento, pero buscando siempre la relación directa entre el consumidor y el comerciante, que la venta se produzca directamente en su tienda (virtual o física) e invitando a su paso por el establecimiento, de tal modo que se personalice la
						relación comercial.
					</StyledText>
				</View>
			</Animatable.View>

			<Animatable.View animation="fadeInUp" delay={600} style={{ flexDirection: SW < 800 ? "column" : "row-reverse", width: "100%", maxWidth: 1080, marginBottom: 50, alignSelf: "center", alignItems: "center", paddingHorizontal: 10 }}>
				<View style={{ flex: 1, width: SW < 800 ? "100%" : null }}>
					<Image style={{ borderRadius: 15, flex: 1, height: 400, resizeMode: "contain", width: "100%" }} source={require("../assets/womenphone.png")} />
				</View>
				<View style={{ flex: 1, marginHorizontal: 10 }}>
					<StyledText style={{ fontSize: 34, marginBottom: 15, fontWeight: "700" }}>Siempre cerca de ti</StyledText>
					<StyledText style={{ fontSize: 18 }}>
						Busca de forma sencilla en el mapa de Madrid tanto productos como establecimientos de los comercios cercanos a tí o en la zona que desees. Nos orientamos a intensificar la relación del comerciante con las organizaciones y de estas entre sí a través del “Club del Comercio de
						Proximidad de Madrid”.
					</StyledText>
				</View>
			</Animatable.View>

			{/* <View style={{ flex: 1, backgroundColor: "white", alignItems: "center", paddingVertical: 25, marginBottom: 80 }}>
				<View style={{ flex: 1, width: "100%", maxWidth: 900, alignItems: "center", flexDirection: SW < 1000 ? "column" : "row", flexWrap: "wrap" }}>
					<Animatable.View animation="fadeInUp" delay={200} style={{ flex: 1, marginVertical: 5, width: "95%", padding: 15, height: SW < 1000 ? 150 : 250, minHeight: 150, borderRadius: 15 }}>
						<Image style={{ borderRadius: 15, flex: 1, position: "absolute", top: 0, bottom: 0, left: 0, right: 0, zIndex: 1 }} source={{ uri: "https://retailhub.es/wp-content/uploads/2019/09/reasons04-6000x2500.png" }} />
						<StyledText style={{ fontSize: 24, fontWeight: "600", color: "white", zIndex: 2 }}>El Club del Comercio de Madrid</StyledText>
					</Animatable.View>
					<Animatable.View animation="fadeInUp" delay={400} style={{ flex: 1, marginVertical: 5, width: "95%", padding: 15, height: SW < 1000 ? 150 : 250, minHeight: 150, marginHorizontal: 10, borderRadius: 15 }}>
						<Image style={{ borderRadius: 15, flex: 1, position: "absolute", top: 0, bottom: 0, left: 0, right: 0, zIndex: 1 }} source={{ uri: "https://retailhub.es/wp-content/uploads/2020/01/bg6-6000x2500.jpg" }} />
						<StyledText style={{ fontSize: 24, fontWeight: "600", color: "white", zIndex: 2 }}>¿Cómo puedes participar en la iniciativa?</StyledText>
					</Animatable.View>
					<Animatable.View animation="fadeInUp" delay={700} style={{ flex: 1, marginVertical: 5, width: "95%", padding: 15, height: SW < 1000 ? 150 : 250, minHeight: 150, borderRadius: 15 }}>
						<Image style={{ borderRadius: 15, flex: 1, position: "absolute", top: 0, bottom: 0, left: 0, right: 0, zIndex: 1 }} source={{ uri: "https://retailhub.es/wp-content/uploads/2020/01/bg5-6000x2500.jpg" }} />
						<StyledText style={{ fontSize: 24, fontWeight: "600", color: "white", zIndex: 2 }}>Qué costes tendrás por participar en la iniciativa?</StyledText>
					</Animatable.View>
				</View>
			</View> */}

			<DownloadBlock />

			<Footer />
		</ScrollView>
	);
};

export default HomeScreen;

const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: "white",
	},
	header: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		paddingLeft: 15,
		paddingRight: 5,
		paddingBottom: 30,
		backgroundColor: "#FFC148",
		width: "100%",
	},
	logo: {
		height: 40,
		width: 150,
		marginBottom: 5,
	},
	searchBackground: {
		height: 25,
		backgroundColor: "#FFC148",
	},
	searchContainer: {
		flexDirection: "row",
		// justifyContent: "space-between",
		// height: 50,
		// marginBottom: 0,
		// marginTop: -25,
		zIndex: 10,
		width: "100%",
		// maxWidth: 600,
	},
	searchBar: {
		width: "100%",
		height: 50,
		maxWidth: 600,
		marginTop: 20,
		borderRadius: 25,
		backgroundColor: "white",
	},
	searchFilterButton: {
		width: 60,
		height: 50,
		justifyContent: "center",
		borderTopLeftRadius: 25,
		borderBottomLeftRadius: 25,
		paddingLeft: 15,
		backgroundColor: "white",
	},
	map: {
		zIndex: 1,
		flex: 1,
		marginTop: -25,
		height: "100%",
		width: "100%",
	},

	shadow: {
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 6,
		},
		shadowOpacity: 0.15,
		shadowRadius: 8.3,

		elevation: 13,
	},
	sideShadow: {
		shadowColor: "#000",
		shadowOffset: {
			width: 5,
			height: 0,
		},
		shadowOpacity: 0.15,
		shadowRadius: 10,

		elevation: 13,
	},
});
