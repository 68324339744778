import React, { useRef, useState } from "react";
// import MapView, { Circle, Marker } from "react-native-maps";
import { StyleSheet, Text, Image, View, ScrollView, FlatList, TouchableOpacity, Platform, TextInput, Dimensions, ActivityIndicator, Alert } from "react-native";
import { Ionicons, MaterialCommunityIcons, Feather } from "@expo/vector-icons";
import DrawerLayout from "react-native-gesture-handler/DrawerLayout";
import RNPickerSelect from "react-native-picker-select";
import Slider from "@react-native-community/slider";

import * as Animatable from "react-native-animatable";
import { useEffect } from "react";

import algoliasearch from "algoliasearch/lite";
import { StackActions, useNavigation } from "@react-navigation/native";
import { StyledText } from "../components/StyledText";
import Map from "../components/Map";
import StoreCard from "../components/StoreCard";

import firebase from "firebase/app";

import * as Location from "expo-location";
import { Helmet } from "react-helmet";
import LinkButton from "../components/LinkButton";
import Header from "../components/Header";
import HTML from "react-native-render-html";
import Footer from "../components/Footer";

const SW = Dimensions.get("window").width;

const PoliciesScreen = ({ route }) => {
	const navigation = useNavigation();

	const { id } = route.params;

	const [data, setData] = useState({});

	useEffect(() => {
		fetch();
	}, [id]);

	const fetch = async () => {
		id &&
			firebase
				.firestore()
				.collection("pages")
				.doc(id)
				.get()
				.then((doc) => {
					setData({ ...doc.data(), id: doc.id });
				})
				.catch((err) => console.log(err));
	};

	return (
		<ScrollView style={styles.container} contentContainerStyle={{ flexGrow: 1 }}>
			{Platform.OS === "web" && (
				<Helmet>
					<meta charSet="utf-8" />
					<title>{data.seoTitle ? data.seoTitle : "Retail Hub Madrid - El comercio de Proximidad de Madrid"}</title>
					<link rel="canonical" href="https://reatilhubmadrid.web.app/home" />
				</Helmet>
			)}
			{/* <Header index={1} /> */}

			<View style={{ width: "100%", paddingVertical: 50, alignItems: "center" }}>
				<Image style={{ position: "absolute", top: 0, left: 0, bottom: 0, right: 0, zIndex: 1, resizeMode: "cover" }} source={{ uri: data.image }} />
				<View style={{ position: "absolute", top: 0, left: 0, bottom: 0, right: 0, zIndex: 2, backgroundColor: "rgba(0,0,0,.5)" }} />
				<Animatable.View animation="fadeInUp" style={{ zIndex: 3, paddingHorizontal: 10, alignItems: "center", maxWidth: 600, justifyContent: "center", flex: 1 }}>
					<StyledText style={{ color: "white", fontSize: 34, fontWeight: "700", textAlign: "center" }}>{data.title}</StyledText>
				</Animatable.View>
			</View>
			<View style={{ flex: 1, backgroundColor: "white", alignItems: "center", paddingVertical: 25 }}>
				<HTML containerStyle={{width:"100%", maxWidth:860}} source={{ html: data.content ? data.content : "<p/>" }} computeEmbeddedMaxWidth={() => 600} />
			</View>

			<Footer />
		</ScrollView>
	);
};

export default PoliciesScreen;

const styles = StyleSheet.create({
	container: {
		flex: 1,
	},
	header: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		paddingLeft: 15,
		paddingRight: 5,
		paddingBottom: 30,
		backgroundColor: "#FFC148",
		width: "100%",
	},
	logo: {
		height: 40,
		width: 150,
		marginBottom: 5,
	},
	searchBackground: {
		height: 25,
		backgroundColor: "#FFC148",
	},
	searchContainer: {
		flexDirection: "row",
		justifyContent: "space-between",
		height: 50,
		marginBottom: 0,
		marginTop: -25,
		zIndex: 10,
	},
	searchBar: {
		width: "100%",
		height: 50,
		maxWidth: 600,
		marginTop: 20,
		borderRadius: 25,
		backgroundColor: "white",
	},
	searchFilterButton: {
		width: 60,
		height: 50,
		justifyContent: "center",
		borderTopLeftRadius: 25,
		borderBottomLeftRadius: 25,
		paddingLeft: 15,
		backgroundColor: "white",
	},
	map: {
		zIndex: 1,
		flex: 1,
		marginTop: -25,
		height: "100%",
		width: "100%",
	},

	shadow: {
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 6,
		},
		shadowOpacity: 0.15,
		shadowRadius: 8.3,

		elevation: 13,
	},
	sideShadow: {
		shadowColor: "#000",
		shadowOffset: {
			width: 5,
			height: 0,
		},
		shadowOpacity: 0.15,
		shadowRadius: 10,

		elevation: 13,
	},
});
