import React from "react";
import { createStackNavigator } from "@react-navigation/stack";

import MapScreen from "../screens/MapScreen";
import StoreScreen from "../screens/StoreScreen";
import ProductsScreen from "../screens/ProductsScreen";
import ProductScreen from "../screens/ProductScreen";
import PromotionsScreen from "../screens/PromotionsScreen";
import PromotionScreen from "../screens/PromotionScreen";
import ArticleScreen from "../screens/ArticleScreen";
import AssociationScreen from "../screens/AssociationScreen";
import MicrositeScreen from "../screens/MicrositeScreen";

const Stack = createStackNavigator();

const SearchStack = () => {
	return (
		<Stack.Navigator headerMode="none">
			<Stack.Screen name="Map" component={MapScreen} />
			<Stack.Screen name="Store" component={StoreScreen} />
			<Stack.Screen name="Association" component={AssociationScreen} />
			<Stack.Screen name="ArticleScreen" component={ArticleScreen} />
			<Stack.Screen name="MicrositeScreen" component={MicrositeScreen} />
			<Stack.Screen name="PromotionsScreen" component={PromotionsScreen} />
			<Stack.Screen name="PromotionScreen" component={PromotionScreen} />
			<Stack.Screen name="ProductsScreen" component={ProductsScreen} />
			<Stack.Screen name="ProductScreen" component={ProductScreen} />
		</Stack.Navigator>
	);
};

export default SearchStack;
