import React from "react";
import { View, TouchableOpacity, Platform } from "react-native";
import { useLinkProps } from "@react-navigation/native";
import { useEffect } from "react/cjs/react.development";

const GrowingElement = ({ onPress, zoom = true, opacity = true, style = {}, clickable = true, children, ...rest }) => {
	const [isHovered, setIsHovered] = React.useState(false);

	if (Platform.OS === "web") {
		return (
			<View onClick={onPress} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)} style={{...style, cursor: clickable ? "pointer" : "cursor", transitionDuration: "150ms", transform: [{ scale: isHovered && zoom ? 1.05 : 1 }], opacity: isHovered && opacity ? 0.8 : 1 }} {...rest}>
				{children}
			</View>
		);
	}

	return (
		<TouchableOpacity onPress={onPress} {...rest}>
			{children}
		</TouchableOpacity>
	);
};

export default GrowingElement;
