import React from "react";
import { Image, View, StyleSheet, TouchableOpacity, Dimensions } from "react-native";
import { StyledText } from "../components/StyledText";
import { FontAwesome } from "@expo/vector-icons";
import * as Animatable from "react-native-animatable";

const SW = Dimensions.get("screen").width;

const PromotionCard = ({ type, title, image, featured, index, onPress, endDate, initDate }) => {
	// console.log(endDate, endDate?.valueOf(), endDate?.toDate().valueOf())
	return (
		<Animatable.View animation="fadeInUp" duration={300} delay={100 * index}>
			<TouchableOpacity onPress={onPress} style={[styles.card, styles.shadow]}>
				{featured && (
					<View style={[styles.badge, styles.shadow]}>
						<FontAwesome name="star" size={25} color="#FFC148" />
					</View>
				)}
				<Image source={{ uri: image }} style={styles.productImage} />
				<View style={styles.overlay} />
				<View style={styles.cardBody}>
					<StyledText style={styles.type}>{type === "RAFFLE" ? "Sorteo" : type === "2X1" ? "2x1" : "Descuento"}</StyledText>
					<StyledText numberOfLines={1} truncate style={styles.title}>
						{title}
					</StyledText>
					{/* <StyledText style={{color:"white"}}>{new Date().valueOf()}  {endDate?.toDate().valueOf()}</StyledText> */}
				</View>
			</TouchableOpacity>
		</Animatable.View>
	);
};

export default PromotionCard;

const styles = StyleSheet.create({
	productImage: {
		flex: 1,
		position: "absolute",
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
		zIndex: 0,
		borderRadius: 10,
	},
	badge: {
		height: 40,
		width: 40,
		borderRadius: 20,
		backgroundColor: "white",
		position: "absolute",
		top: -5,
		right: -5,
		zIndex: 10,
		alignItems: "center",
		justifyContent: "center",
	},
	overlay: {
		flex: 1,
		position: "absolute",
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
		zIndex: 1,
		backgroundColor: "rgba(0,0,0,.5)",
		borderRadius: 10,
	},
	card: {
		flex: 1,
		height: 110,
		borderRadius: 15,
		marginBottom: 10,
	},
	cardBody: {
		flex: 1,
		paddingHorizontal: 25,
		justifyContent: "center",
		zIndex: 3,
	},
	type: {
		fontSize: 34,
		fontWeight: "700",
		color: "white",
	},
	title: {
		fontSize: 14,
		fontWeight: "700",
		color: "white",
	},

	shadow: {
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 6,
		},
		shadowOpacity: 0.15,
		shadowRadius: 8.3,

		elevation: 13,
	},
});
