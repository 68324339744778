import React, { useEffect, useState, useRef } from "react";
import { View, StyleSheet, Platform, Image } from "react-native";

import decodePolyline from "decode-google-map-polyline";
import { StyledText } from "./StyledText";

let MapView, Marker, Circle, Polyline;
if (Platform.OS === "web") {
	MapView = require("react-native-web-maps").default;
	Marker = require("react-native-web-maps").default.Marker;
	Circle = require("react-native-web-maps").default.Circle;
	Polyline = require("react-native-web-maps").default.Polyline;
} else {
	MapView = require("react-native-map-clustering").default;
	Marker = require("react-native-maps").Marker;
	Circle = require("react-native-maps").Circle;
	Polyline = require("react-native-maps").Polyline;
}

const MapRoute = ({ steps = [], onPress, itemSelected, route = null }) => {
	const [dragging, setDragging] = useState(false);
	const [coord, setCoord] = useState([]);
	const [focusPoint, setFocusPoint] = useState(null);
	const mapRef = useRef();
	const mapStyle = [
		{
			featureType: "administrative",
			elementType: "labels",
			// stylers: [{ visibility: "off" }],
		},
		{
			featureType: "poi",
			elementType: "labels",
			stylers: [{ visibility: "off" }],
		},
		{
			featureType: "water",
			elementType: "labels",
			stylers: [{ visibility: "off" }],
		},
		{
			featureType: "road",
			elementType: "labels",
			// stylers: [{ visibility: "off" }],
		},
		{
			featureType: "landscape",
			elementType: "labels",
			stylers: [
				{
					visibility: "off",
				},
			],
		},
	];

	const mapStyleNative = [
		{
			elementType: "labels",
			stylers: [
				{
					visibility: "off",
				},
			],
		},
		{
			featureType: "transit",
			elementType: "labels",
			stylers: [{ visibility: "on" }],
		},
		{
			featureType: "road",
			elementType: "labels",
			stylers: [{ visibility: "on" }],
		},
		{
			featureType: "administrative",
			elementType: "labels",
			stylers: [
				{
					visibility: "on",
				},
			],
		},
	];

	
	useEffect(() => {
		route && setCoord(decodePolyline(route));
	}, [route]);

	useEffect(() => {
		// console.log(steps[itemSelected]);
		if (itemSelected !== null) {
			setFocusPoint({
				lat: steps[itemSelected].coordinates.latitude,
				lng: steps[itemSelected].coordinates.longitude,
			});
		}
	}, [itemSelected]);

	return (
		<MapView
			ref={mapRef}
			showsBuildings={true}
			minZoomLevel={5}
			pitchEnabled={false}
			showsUserLocation={false}
			scrollEnabled={Platform.OS === "android" ? (dragging ? false : true) : true}
			initialRegion={{
				latitude: 40.4110238913975,
				longitude: -3.69678454980386,
			}}
			showsPointsOfInterest={false}
			showsCompass={false}
			showsScale={false}
			toolbarEnabled={false}
			spiralEnabled={true}
			clusterColor="#ffc148"
			clickableIcons={false}
			mapType="mutedStandard"
			zoom={28}
			options={{
				mapTypeControl: false,
				streetViewControl: false,
				fullscreenControl: false,
				backgroundColor: "#fefefe",
				zoom: focusPoint ? 15 : 14,
				center: focusPoint || {
					lat: 40.4110238913975,
					lng: -3.69678454980386,
				},
				styles: mapStyle,
			}}
			customMapStyle={mapStyleNative}
			style={styles.map}
		>
			<Polyline
				// coordinates={coord}
				path={coord}
				strokeColor="#000" // fallback for when `strokeColors` is not supported by the map-provider
				strokeWidth={6}
				// editable={true}
				options={{
					strokeColor: "orange",
					strokeOpacity: 0.55,
					strokeWeight: 5,
					icons: [
						{
							icon: "https://i.morioh.com/RFG2le2.png",
							offset: "0",
							repeat: "10px",
						},
					],
				}}
			/>

			{steps.map((item, i) => (
				<Marker
					key={i}
					zIndex={itemSelected?.objectID === item.objectID ? 15 : 5}
					icon={{
						url: item.mapIcon
							? item.mapIcon
							: "https://firebasestorage.googleapis.com/v0/b/retailhub-6602d.appspot.com/o/public%2Fdefaultmarkerpoi.png?alt=media&token=3e8a5df6-defc-44c6-bd87-7480b72ff1f7",
						scaledSize: { width:  item?.mapIcon ? itemSelected === i ? 45 : 35 : 25, height: item?.mapIcon ? itemSelected === i ? 45 : 35 : 25 },
					}}
					style={{
						filter: "hue-rotate(15deg)"
					}}
					onPress={() => onPress(i)}
					coordinate={{
						latitude: parseFloat(item?._geoloc?.lat),
						longitude: parseFloat(item?._geoloc?.lng),
					}}
				/>
			))}
		</MapView>
	);
};

export default MapRoute;

const styles = StyleSheet.create({
	map: {
		zIndex: 1,
		flex: 1,
		marginTop: -25,
		height: "100%",
		width: "100%",
	},

	shadow: {
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 6,
		},
		shadowOpacity: 0.15,
		shadowRadius: 8.3,

		elevation: 13,
	},
});
