import React from "react";
import { StyleSheet, View, TouchableOpacity } from "react-native";
import { StyledText } from "../components/StyledText";
import * as Animatable from "react-native-animatable";

const CategoryLineComponent = ({ title = "", amount = 0, index }) => {
	return (
		<Animatable.View animation="fadeInUp" duration={300} delay={100 * index}>
			<TouchableOpacity style={styles.categoryContainer}>
				<StyledText style={styles.categoryTitle}>{title}</StyledText>
			</TouchableOpacity>
		</Animatable.View>
	);
};

export default CategoryLineComponent;

const styles = StyleSheet.create({
	categoryContainer: {
		height: 70,
		width: "100%",
		alignItems: "center",
		justifyContent: "space-between",
		flexDirection: "row",
		borderBottomWidth: 1,
		paddingHorizontal: 10,
		borderColor: "gainsboro",
	},
	categoryTitle: {
		fontSize: 20,
		color: "#595959",
		fontWeight: "700",
	},
	categoryNumbers: {
		fontSize: 14,
		color: "gray",
	},
});
