import React from "react";
import { createStackNavigator } from "@react-navigation/stack";

import PromotionsSearchScreen from "../screens/PromotionsSearchScreen";
import Header from "../components/Header";
import { useNavigationState } from "@react-navigation/native";

const Stack = createStackNavigator();

const PromotionsStack = () => {
	const index = useNavigationState((state) => state.index);
	return (
		<Stack.Navigator
			screenOptions={{
				header: () => <Header index={index} />,
			}}>
			<Stack.Screen name="PromotionsSearchScreen" component={PromotionsSearchScreen} />
		</Stack.Navigator>
	);
};

export default PromotionsStack;
