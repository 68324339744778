import React, { useContext, useEffect, useState } from "react";
import { Image, View, TouchableOpacity, StyleSheet, Dimensions } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import { Ionicons } from "@expo/vector-icons";
import { StyledText } from "../components/StyledText";

import moment from "moment";

import firebase from "firebase/app";
import HTML from "react-native-render-html";
import AuthContext from "../context/AuthContext";

const SW = Dimensions.get("screen").width;
const SH = Dimensions.get("screen").height;
const StoreScreen = ({ navigation, route }) => {
	const [data, setData] = useState({});
	const { userData } = useContext(AuthContext);
	const [ownedCoupon, setOwnedCoupon] = useState(false);
	const [loading, setLoading] = useState(false);
	const id = route.params?.id;

	useEffect(() => {
		firebase
			.firestore()
			.collection("promotions")
			.doc(id)
			.get()
			.then((doc) => {
				setData(doc.data());
			});
	}, []);

	// useEffect(() => {
	// 	let subscription;
	// 	if (userData.userID) {
	// 		subscription = firebase
	// 			.firestore()
	// 			.collection("users")
	// 			.doc(userData.userID)
	// 			.collection("promotions")
	// 			.where("promotion", "==", id)
	// 			.get()
	// 			.then((docs) => {
	// 				if (docs.docs.length > 0) {
	// 					setOwnedCoupon(true);
	// 				} else {
	// 					setOwnedCoupon(true);
	// 				}
	// 			});
	// 	}
	// 	return () => subscription();
	// }, []);

	const createPromotion = async () => {
		setLoading(true);
		firebase.firestore
			.collection("users")
			.doc(userData.userID)
			.collection("promotions")
			.create({
				promotion: id,
				redemed: false,
				owner: userData.userID,
				type: data.type,
			})
			.then((res) => {
				setLoading(false);
				setOwnedCoupon(true);
			})
			.catch(setLoading(false));
	};

	return (
		<ScrollView style={{ backgroundColor: "white" }} contentContainerStyle={{ alignSelf: "center", flexGrow: 1, width: "100%", maxWidth: 600 }}>
			<View style={[styles.card, styles.shadow]}>
				<Image source={{ uri: data.image ? data.image : "https://retailhub.es/wp-content/uploads/2020/12/landing.jpg" }} style={styles.productImage} />
				<View style={styles.overlay} />
				<View style={styles.cardBody}>
					<StyledText style={styles.type}>{data.type}</StyledText>
					<StyledText numberOfLines={1} truncate style={styles.cardTitle}>
						{data.name}
					</StyledText>
				</View>
				<View style={styles.cardFooter}>
					<View style={{ flex: 1, marginRight: 15, justifyContent: "flex-end" }}>
						<StyledText style={styles.subtitle}>{data.units && data.units + " disponibles"}</StyledText>
					</View>
					<View style={{ flex: 1 }}>{/* <StyledText numberOfLines={2} truncate style={[styles.subtitle, { textAlign: "right" }]}>
							Cafeteria los morales
						</StyledText> */}</View>
				</View>
			</View>

			<View style={[styles.bodyContainer, { flexGrow: 1 }]}>
				<StyledText style={styles.label}>Validez</StyledText>
				<View style={[styles.time, styles.shadow]}>
					<Ionicons name="calendar-outline" size={30} />
					<StyledText style={styles.timeText}>{data.unlimited ? "Sin fecha final" : `Desde ${moment(data.initDate?.toDate()).format("DD-MM-YYYY")} al ${moment(data.endDate?.toDate()).format("DD-MM-YYYY")}`}</StyledText>
				</View>

				<StyledText style={styles.label}>Detalles</StyledText>
				<View style={[styles.cardDescription, styles.shadow]}>
					<HTML source={{ html: data.description ? data.description : "<p/>" }} />
				</View>
			</View>
			<View style={[styles.bodyContainer, { paddingBottom: 15 }]}>
				<TouchableOpacity onPress={createPromotion} disabled={ownedCoupon} style={[styles.button, { opacity: ownedCoupon ? 0.4 : 1 }]}>
					<StyledText style={styles.buttonText}>Descargar Promoción</StyledText>
				</TouchableOpacity>
			</View>
		</ScrollView>
	);
};

export default StoreScreen;

const styles = StyleSheet.create({
	button: {
		height: 50,
		width: "100%",
		backgroundColor: "#FFC148",
		alignItems: "center",
		justifyContent: "center",
		borderRadius: 7,
	},
	bodyContainer: {
		paddingHorizontal: 15,
	},
	title: {
		fontSize: 24,
		fontWeight: "700",
	},
	store: {
		fontSize: 18,
		color: "#FFC148",
		marginVertical: 5,
	},
	label: {
		fontSize: 18,
		fontWeight: "700",
		color: "gainsboro",
	},
	time: {
		width: "100%",
		height: 50,
		flexDirection: "row",
		alignItems: "center",
		backgroundColor: "white",
		borderRadius: 10,
		marginVertical: 10,
		paddingHorizontal: 15,
	},
	timeText: {
		fontSize: 18,
		fontWeight: "700",
		marginLeft: 10,
	},
	cardDescription: {
		width: "100%",
		backgroundColor: "white",
		borderRadius: 10,
		marginTop: 10,
		padding: 15,
	},
	priceBlock: {
		height: 50,
		flexDirection: "row",
		width: "100%",
		backgroundColor: "white",
		marginTop: 30,
		marginBottom: 20,
		borderRadius: 10,
	},
	priceContainer: {
		width: "50%",
		flex: 1,
		alignItems: "center",
		justifyContent: "center",
	},
	price: {
		fontSize: 24,
		fontWeight: "700",
	},
	buttonText: {
		fontSize: 18,
		color: "white",
		fontWeight: "700",
	},
	body: {
		marginTop: 15,
	},
	heading: {
		fontSize: 18,
		marginTop: 15,
	},
	socialRow: {
		flexDirection: "row",
		marginTop: 15,
	},
	socialIcon: {
		marginRight: 10,
		height: 40,
		width: 40,
		alignItems: "center",
		justifyContent: "center",
	},
	galleryContainer: {
		backgroundColor: "#F3F3F3",
		paddingHorizontal: 5,
		paddingBottom: 15,
	},
	gallery: {
		flexDirection: "row",
		flexWrap: "wrap",
	},
	galleryItem: {
		width: (SW - 50) / 3,
		height: (SW - 50) / 3,
		margin: 5,
		borderRadius: 10,
		resizeMode: "cover",
	},
	miniMapContainer: {
		height: 220,
		borderRadius: 10,
		overflow: "hidden",
		width: "100%",
		backgroundColor: "white",
	},
	shadow: {
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 6,
		},
		shadowOpacity: 0.15,
		shadowRadius: 8.3,

		elevation: 13,
	},
	productImage: {
		flex: 1,
		position: "absolute",
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
		zIndex: 0,
		borderRadius: 10,
	},
	badge: {
		height: 40,
		width: 40,
		borderRadius: 20,
		backgroundColor: "white",
		position: "absolute",
		top: -5,
		right: -5,
		zIndex: 10,
		alignItems: "center",
		justifyContent: "center",
	},
	overlay: {
		flex: 1,
		position: "absolute",
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
		zIndex: 1,
		backgroundColor: "rgba(0,0,0,.6)",
		borderRadius: 10,
	},
	card: {
		height: 180,
		borderRadius: 15,
		margin: 15,
	},
	cardBody: {
		flex: 1,
		paddingHorizontal: 25,
		paddingTop: 15,
		zIndex: 3,
	},
	cardFooter: {
		flexDirection: "row",
		width: "100%",
		justifyContent: "space-between",
		zIndex: 10,
		paddingHorizontal: 20,
		paddingBottom: 10,
	},
	type: {
		fontSize: 44,
		fontWeight: "700",
		color: "white",
	},
	cardTitle: {
		fontSize: 18,
		fontWeight: "700",
		color: "white",
	},
	subtitle: {
		fontSize: 16,
		color: "white",
	},
	shadow: {
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 6,
		},
		shadowOpacity: 0.15,
		shadowRadius: 8.3,

		elevation: 13,
	},
});
