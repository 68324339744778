import React, { useState, useEffect } from "react";
import { Image, View, StyleSheet, ScrollView, Platform, TouchableOpacity, TextInput, Dimensions } from "react-native";
import { Ionicons, Feather, MaterialCommunityIcons } from "@expo/vector-icons";
import { StyledText } from "../components/StyledText";
import { useNavigation } from "@react-navigation/native";
import PromotionCard from "../components/PromotionCard";
import firebase from "firebase/app";
import ButtonsStore from "../components/ButtonsStore";
import { Helmet } from "react-helmet";

const SW = Dimensions.get("screen").width;
const PromotionsScreen = ({ navigation, route }) => {
	const id = route.params?.id;

	const [tab, setTab] = useState(0);
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState([]);
	const [store, setStore] = useState({});
	const [gallerySize, setGallerySize] = useState(600);

	useEffect(() => {
		id && fetchData();
	}, []);

	const fetchData = async () => {
		firebase
			.firestore()
			.collection("stores")
			.doc(id)
			.get()
			.then((doc) => {
				setStore({ ...doc.data(), id: doc.id });
				setLoading(false);
			})
			.catch((err) => {
				setLoading(false);
			});
		firebase
			.firestore()
			.collection("promotions")
			.where("stores", "array-contains", id)
			.get()
			.then((querySnapShot) => {
				const prom = querySnapShot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
				// console.log(prom);
				setData(prom);
			});
	};

	return (
		<View style={{ flex: 1 }}>
			{Platform.OS === "web" && (
				<Helmet>
					<meta charSet="utf-8" />
					<title>Promociones{" " + store.name} - Retail Hub Madrid</title>
					<link rel="canonical" href="https://reatilhubmadrid.web.app/home" />
				</Helmet>
			)}

			<ScrollView style={{ backgroundColor: "white", height: 150 }} contentContainerStyle={{ alignItems: "center" }}>
				<View style={styles.headerContainer}>
					<View style={styles.headerOverlay} />
					<Image style={styles.headerBackground} source={{ uri: store.images && store.images[0] ? store.images[0] : "https://oij.org/wp-content/uploads/2016/05/placeholder.png" }} />
					<StyledText style={styles.title}>{store.name}</StyledText>
				</View>
				<ButtonsStore id={id} index={2} />

				<View style={styles.tabSelector}>
					<View style={{ maxWidth: 600, width: "100%", flexDirection: "row" }}>
						<TouchableOpacity onPress={() => setTab(0)} style={[styles.tab, tab === 0 ? styles.tabSelected : null]}>
							<StyledText style={[styles.tabText, tab === 0 ? styles.tabTextSelected : null]}>Promociones Activas</StyledText>
						</TouchableOpacity>
						<TouchableOpacity onPress={() => setTab(1)} style={[styles.tab, tab === 1 ? styles.tabSelected : null]}>
							<StyledText style={[styles.tabText, tab === 1 ? styles.tabTextSelected : null]}>Promociones Pasadas</StyledText>
						</TouchableOpacity>
					</View>
				</View>

				<View style={{ maxWidth: 600, width: "100%" }}>
					<View style={styles.productsContainer}>
						{tab === 0 ? (
							<View style={{ width: "100%" }}>
								{data.filter((el) => el.unlimited || el.endDate && new Date().valueOf() < el.endDate?.toDate().valueOf()).length === 0 ? (
									<View style={{ backgroundColor: "#f3f3f3", borderRadius: 15, alignItems: "center", justifyContent: "center", height: 300, width: "100%" }}>
										<StyledText style={{ fontSize: 18, opacity: 0.2, fontWeight: "700" }}>Sin Promociones Activas</StyledText>
									</View>
								) : (
									data
										.filter((el) => el.unlimited || el.endDate && new Date().valueOf() < el.endDate?.toDate().valueOf())
										.map((prom, i) => (
											<PromotionCard
												key={i}
												onPress={() => navigation.navigate("PromotionScreen", { id: prom.id })}
												index={i}
												title={prom.name}
												type={prom.type}
												endDate={prom.endDate && prom.endDate !== "" ? prom.endDate : null}
												image={prom.image ?? "https://images.pexels.com/photos/1488463/pexels-photo-1488463.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"}
											/>
										))
								)}
							</View>
						) : (
							<View style={{ width: "100%" }}>
								{data.filter((el) => !el.unlimited && el.endDate && new Date().valueOf() > el.endDate?.toDate().valueOf()).length === 0 ? (
									<View style={{ backgroundColor: "#f3f3f3", borderRadius: 15, alignItems: "center", justifyContent: "center", height: 300, width: "100%" }}>
										<StyledText style={{ fontSize: 18, opacity: 0.2, fontWeight: "700" }}>Sin Promociones Anteriores</StyledText>
									</View>
								) : (
									data
										.filter((el) => !el.unlimited && el.endDate && new Date().valueOf() > el.endDate?.toDate().valueOf())
										.map((prom, i) => (
											<PromotionCard
												key={i}
												onPress={() => navigation.navigate("PromotionScreen", { id: prom.id })}
												index={i}
												title={prom.name}
												type={prom.type}
												endDate={prom.endDate && prom.endDate !== "" ? prom.endDate : null}
												image={prom.image ?? "https://images.pexels.com/photos/1488463/pexels-photo-1488463.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"}
											/>
										))
								)}
							</View>
						)}
					</View>
				</View>
			</ScrollView>
		</View>
	);
};

export default PromotionsScreen;

const styles = StyleSheet.create({
	headerContainer: {
		height: 130,
		width: "100%",
		borderBottomLeftRadius: 10,
		borderBottomRightRadius: 10,
		overflow: "hidden",
		alignItems: "center",
		justifyContent: "center",
	},
	headerOverlay: {
		flex: 1,
		position: "absolute",
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
		backgroundColor: "rgba(0,0,0,0.5)",
		zIndex: 2,
	},
	headerBackground: {
		resizeMode: "cover",
		flex: 1,
		position: "absolute",
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
		zIndex: 1,
	},
	buttonsRow: {
		flexDirection: "row",
		paddingHorizontal: 15,
		marginTop: -25,
		zIndex: 2,
	},
	button: {
		height: 50,
		width: 50,
		backgroundColor: "white",
		alignItems: "center",
		justifyContent: "center",
		borderRadius: 7,
		marginRight: 10,
	},
	bodyContainer: {
		padding: 15,
	},
	title: {
		fontSize: 24,
		fontWeight: "700",
		color: "white",
		zIndex: 4,
		marginBottom: 15,
		marginHorizontal:10
	},
	searchSection: {
		backgroundColor: "#f3f3f3",
		paddingHorizontal: 15,
		paddingTop: 50,
		marginTop: -25,
		zIndex: 1,
	},
	searchBar: {
		height: 45,
		flexDirection: "row",
		backgroundColor: "white",
		borderRadius: 22.5,
		position: "relative",
	},
	searchButton: {
		width: 45,
		height: 45,
		alignItems: "center",
		justifyContent: "center",
		position: "absolute",
		right: 0,
		top: 0,
		zIndex: 10,
	},
	body: {
		marginTop: 15,
	},
	tabSelector: {
		paddingTop: 45,
		marginTop: -25,
		width: "100%",
		alignItems: "center",
		backgroundColor: "#f3f3f3",
	},
	tab: {
		borderBottomWidth: 0,
		flex: 1,
		minHeight: 45,
		paddingVertical: 5,
		paddingHorizontal: 15,
		alignItems: "center",
		justifyContent: "center",
		borderBottomColor: "#FFC148",
	},
	tabSelected: {
		borderBottomWidth: 4,
		flex: 1,
		borderBottomColor: "#FFC148",
	},
	tabText: {
		fontSize: 18,
		fontWeight: "700",
		opacity: 0.2,
		textAlign: "center",
	},
	tabTextSelected: {
		opacity: 1,
		color: "#FFC148",
	},
	productsContainer: {
		flexDirection: "row",
		flexWrap: "wrap",
		padding: 10,
	},

	shadow: {
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 6,
		},
		shadowOpacity: 0.15,
		shadowRadius: 8.3,

		elevation: 13,
	},
});
