import React, { useEffect } from "react";
import { Image, View, StyleSheet, Linking, Platform, ScrollView, TouchableOpacity } from "react-native";
import { Ionicons, Feather, MaterialCommunityIcons } from "@expo/vector-icons";
import { StyledText } from "../components/StyledText";
import HTML from "react-native-render-html";

import { useNavigation } from "@react-navigation/native";

import { useState } from "react";
import firebase from "firebase/app";
import ButtonsStore from "../components/ButtonsStore";
import { Helmet } from "react-helmet";

let MapView, Marker, Circle;
if (Platform.OS === "web") {
	MapView = require("react-native-web-maps").default;
	Marker = require("react-native-web-maps").default.Marker;
	Circle = require("react-native-web-maps").default.Circle;
} else {
	MapView = require("react-native-map-clustering").default;
	Marker = require("react-native-maps").Marker;
	Circle = require("react-native-maps").Circle;
}

const StoreScreen = ({ route, navigation }) => {
	const id = route.params?.id;
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState({});
	const [gallerySize, setGallerySize] = useState(600);

	useEffect(() => {
		id && fetchData();
		setLoading(true);
	}, [id]);

	const fetchData = async () => {
		firebase
			.firestore()
			.collection("stores")
			.doc(id)
			.get()
			.then((doc) => {
				setData({ ...doc.data(), id: doc.id });
				// console.log({ ...doc.data(), id: doc.id });
				setLoading(false);
			})
			.catch((err) => {
				setLoading(false);
			});
	};

	const handleShare = (href, target) => {
		// WebBrowser.openBrowserAsync(this.props.href)
		// console.log(window.location)
		Linking.canOpenURL(href, target)
			.then((supported) => {
				if (!supported) {
					console.log("Can't handle url: " + href, target);
				} else {
					if (Platform.OS === "web") {
						return window.open(href, target);
					} else {
						return Linking.openURL(href, target);
					}
				}
			})
			.catch((err) => console.error("An error occurred", err));
	};

	return (
		<View style={{ flex: 1 }}>
			{Platform.OS === "web" && (
				<Helmet>
					<meta charSet="utf-8" />
					<title>{data.name ? `${data.name} - Retail Hub Madrid` : "Retail Hub Madrid"}</title>
					<link rel="canonical" href={`https://retailhubmadrid.web.app/comercio/${data.name}`} />
				</Helmet>
			)}
			<ScrollView style={{ backgroundColor: "white", height: 150 }} contentContainerStyle={{ alignItems: "center" }}>
				<View style={styles.headerContainer}>
					<Image style={styles.headerBackground} source={{ uri: data.images && data.images[0] ? data.images[0] : "https://oij.org/wp-content/uploads/2016/05/placeholder.png" }} />
				</View>

				<ButtonsStore id={id} index={0} />

				<View style={{ maxWidth: 600, width: "100%" }}>
					<View style={styles.bodyContainer}>
						<StyledText style={styles.title}>{data.name}</StyledText>
						<HTML source={{ html: data.description ? data.description : "<p/>" }} contentWidth={600} />
						{data.links?.reduce((acc, cur) => acc + (cur !== "" ? 1 : 0), 0) > 0 && (
							<>
								<StyledText style={styles.heading}>Información</StyledText>
								<View style={styles.socialRow}>
									{data.links.map((link, i) => {
										return (
											link !== "" && (
												<TouchableOpacity
													key={i}
													onPress={() =>
														handleShare(
															i === 6
																? `tel:${link}`
																: i === 5
																? `mailto:?subject=${data.name} en Retail Hub Madrid&body=He encontrado ${data.name} en Retail Hub Madrid ${window.location}.`
																: link,
															"_blank"
														)
													}
													style={[styles.socialIcon, { flexDirection: i === 6 ? "row" : null, width: i === 6 ? "auto" : null }]}
												>
													<Ionicons
														name={
															i === 0
																? "globe-outline"
																: i === 1
																? "logo-instagram"
																: i === 2
																? "logo-facebook"
																: i === 3
																? "logo-twitter"
																: i === 4
																? "logo-youtube"
																: i === 5
																? "mail-open"
																: "call"
														}
														size={i === 6 ? 30 : 35}
													/>
													{i === 6 && link !== "" && <StyledText style={{ marginLeft: 5, fontSize: 16, fontWeight: "700" }}>{link}</StyledText>}
												</TouchableOpacity>
											)
										);
									})}
								</View>
							</>
						)}
					</View>
					<View style={styles.galleryContainer}>
						<StyledText style={[styles.heading, { marginLeft: 5, marginBottom: 5 }]}>Galería</StyledText>
						<View
							style={styles.gallery}
							onLayout={({
								nativeEvent: {
									layout: { width, height },
								},
							}) => setGallerySize({ width, height })}
						>
							{data.images?.length === 0 ? (
								<View
									style={{
										height: 250,
										backgroundColor: "white",
										opacity: 0.3,
										borderRadius: 15,
										marginTop: 15,
										marginHorizontal: "2.5%",
										width: "95%",
										alignItems: "center",
										justifyContent: "center",
									}}
								>
									<StyledText style={[styles.heading, { marginLeft: 5, marginBottom: 5 }]}>Sin Imágenes</StyledText>
								</View>
							) : (
								data.images?.map((image, i) => (
									<Image key={i} style={[styles.galleryItem, { width: (gallerySize.width - 30) / 3, height: (gallerySize.width - 30) / 3 }]} source={{ uri: image }} />
								))
							)}
						</View>
					</View>

					<View style={styles.bodyContainer}>
						<StyledText style={styles.heading}>Localización y Horarios</StyledText>

						<View style={{ paddingHorizontal: 10, paddingBottom: 15, marginTop: 10, borderRadius: 10, backgroundColor: "#f3f3f3" }}>
							{data.schedule && data.schedule.length === 0 ? (
								<View
									style={{
										height: 80,
										backgroundColor: "white",
										opacity: 0.3,
										borderRadius: 15,
										marginTop: 15,
										marginHorizontal: "2.5%",
										width: "95%",
										alignItems: "center",
										justifyContent: "center",
									}}
								>
									<StyledText style={[styles.heading, { marginLeft: 5, marginBottom: 5 }]}>Sin Horarios</StyledText>
								</View>
							) : (
								data.schedule?.map((time, i) => (
									<StyledText style={styles.body} key={i}>
										{time}
									</StyledText>
								))
							)}
						</View>
						<StyledText style={[styles.body, { fontWeight: "700" }]}>{data.fullAddress}</StyledText>
					</View>
					<View style={[styles.bodyContainer]}>
						<View style={styles.shadow}>
							{data._geoloc && (
								<MapView
									initialRegion={{
										latitude: data._geoloc?.lat,
										longitude: data._geoloc?.lng,
										latitudeDelta: 0.0152,
										longitudeDelta: 0.0152,
									}}
									options={
										Platform.OS === "web"
											? {
													mapTypeControl: false,
													streetViewControl: false,
													fullscreenControl: false,
													backgroundColor: "#fefefe",
													styles: mapStyle,
											  }
											: null
									}
									mapType="mutedStandard"
									style={styles.miniMapContainer}
								>
									<Marker
										icon={{ url: "https://cdn0.iconfinder.com/data/icons/small-n-flat/24/678111-map-marker-512.png", scaledSize: { width: 45, height: 45 } }}
										coordinate={{
											latitude: data._geoloc?.lat,
											longitude: data._geoloc?.lng,
										}}
									>
										<View style={{ width: 25, height: 25, backgroundColor: "white", borderRadius: 17, borderWidth: 4, borderColor: "#FFC148" }} />
									</Marker>
								</MapView>
							)}
						</View>
					</View>
				</View>
			</ScrollView>
		</View>
	);
};

export default StoreScreen;

const styles = StyleSheet.create({
	headerContainer: {
		height: 200,
		width: "100%",
		borderBottomLeftRadius: 10,
		borderBottomRightRadius: 10,
		overflow: "hidden",
	},
	headerBackground: {
		resizeMode: "cover",
		flex: 1,
		position: "absolute",
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
	},
	buttonsRow: {
		flexDirection: "row",
		paddingHorizontal: 15,
		marginTop: -25,
	},
	button: {
		height: 50,
		width: 50,
		backgroundColor: "white",
		alignItems: "center",
		justifyContent: "center",
		borderRadius: 7,
		marginRight: 10,
	},
	bodyContainer: {
		padding: 15,
	},
	title: {
		fontSize: 24,
		fontWeight: "700",
	},
	body: {
		marginTop: 15,
	},
	heading: {
		fontSize: 18,
		marginTop: 15,
		fontWeight: "700",
	},
	socialRow: {
		flexDirection: "row",
		marginTop: 15,
	},
	socialIcon: {
		marginRight: 10,
		height: 40,
		width: 40,
		alignItems: "center",
		justifyContent: "center",
	},
	galleryContainer: {
		backgroundColor: "#F3F3F3",
		paddingHorizontal: 5,
		paddingBottom: 15,
	},
	gallery: {
		flexDirection: "row",
		flexWrap: "wrap",
	},
	galleryItem: {
		margin: 5,
		borderRadius: 10,
		resizeMode: "cover",
	},
	miniMapContainer: {
		height: 220,
		borderRadius: 10,
		overflow: "hidden",
		width: "100%",
		backgroundColor: "white",
	},
	shadow: {
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 6,
		},
		shadowOpacity: 0.15,
		shadowRadius: 8.3,

		elevation: 13,
	},
});

const mapStyle = [
	{
		featureType: "administrative",
		elementType: "labels",
		stylers: [{ visibility: "off" }],
	},
	{
		featureType: "poi",
		elementType: "labels",
		stylers: [{ visibility: "off" }],
	},
	{
		featureType: "water",
		elementType: "labels",
		stylers: [{ visibility: "off" }],
	},
	{
		featureType: "road",
		elementType: "labels",
		stylers: [{ visibility: "off" }],
	},
	{
		featureType: "landscape",
		elementType: "labels",
		stylers: [
			{
				visibility: "off",
			},
		],
	},
];
