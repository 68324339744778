import React from "react";
import { StyleSheet, View, TouchableOpacity } from "react-native";
import { StyledText } from "../components/StyledText";
import * as Animatable from "react-native-animatable";


const CategoryLineComponent = ({ title = "", amount = 0, index, selected, onPress }) => {
	return (
		<Animatable.View animation="fadeInUp" duration={300} delay={100 * index}>
			<TouchableOpacity onPress={onPress} style={styles.categoryContainer}>
				<StyledText style={[styles.categoryTitle, { color: selected ? "#FFC148" : null }]}>{title}</StyledText>
				<View>
					<StyledText style={styles.categoryNumbers}>{amount + " prod"}</StyledText>
				</View>
			</TouchableOpacity>
		</Animatable.View>
	);
};

export default CategoryLineComponent;

const styles = StyleSheet.create({
	categoryContainer: {
		height: 50,
		width: "100%",
		alignItems: "center",
		justifyContent: "space-between",
		flexDirection: "row",
		borderBottomWidth: 1,
		paddingHorizontal: 10,
		borderColor: "gainsboro",
	},
	categoryTitle: {
		fontSize: 16,
		color: "#595959",
		fontWeight: "700",
	},
	categoryNumbers: {
		fontSize: 12,
		color: "gray",
	},
});
