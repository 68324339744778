import React, { useRef } from "react";

import { StyleSheet, Text, Image, View, Dimensions, TouchableOpacity, TextInput } from "react-native";
import { Ionicons, Feather, MaterialCommunityIcons } from "@expo/vector-icons";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { StackActions, useRoute } from "@react-navigation/native";

const BottomTabBar = ({ route, state, descriptors, navigation }) => {
	const insets = useSafeAreaInsets();
	
	const { navigate } = navigation;
	
	const { index } = state;
	return (
		<View style={[styles.footer, styles.shadowInverted, { paddingBottom: insets.bottom ? insets.bottom -10 : 5 }]}>
			<TouchableOpacity onPress={() => navigate("Home")} style={{ height: 60, width: 60, alignItems: "center", backgroundColor: index === 0 ? "white" : null, borderRadius: 30,  justifyContent: "center" }}>
				<MaterialCommunityIcons name="home-variant" size={35} color="#4C4C4C" style={{ opacity: index === 0 ? 1 : 0.3 }} />
			</TouchableOpacity>
			<TouchableOpacity onPress={() => {
				navigate("Search", { screen: "MapScreen" })
				// setTimeout(() =>{
				// 	navigation.dispatch(StackActions.popToTop());
				// }, 300)
				}} style={{ height: 60, width: 60, backgroundColor: index === 1 ? "white" : null, borderRadius: 30, alignItems: "center", justifyContent: "center" }}>
				<Feather name="map-pin" size={35} color="#4C4C4C" style={{ opacity: index === 1 ? 1 : 0.3 }} />
			</TouchableOpacity>
			<TouchableOpacity onPress={() => navigate("Profile")} style={{ height: 60, width: 60, alignItems: "center", backgroundColor: index === 2 ? "white" : null, borderRadius: 30,  justifyContent: "center" }}>
				<Ionicons name="person-circle-outline" size={40} color="#4C4C4C" style={{ opacity: index === 2 ? 1 : 0.3 }} />
			</TouchableOpacity>
		</View>
	);
};

export default BottomTabBar;

const styles = StyleSheet.create({
	footer: {
		zIndex: 10,
		paddingTop: 5,
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",
		paddingHorizontal: 50,
		backgroundColor: "#FFC148",
		width: "100%",
	},
	shadowInverted: {
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: -6,
		},
		shadowOpacity: 0.15,
		shadowRadius: 8.3,

		elevation: 13,
	},
});
