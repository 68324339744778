import React, { useRef, useState } from "react";
// import MapView, { Circle, Marker } from "react-native-maps";
import {
	StyleSheet,
	Text,
	Image,
	View,
	ScrollView,
	FlatList,
	TouchableOpacity,
	Platform,
	TextInput,
	Dimensions,
	ActivityIndicator,
	Modal,
	Touchable,
	Pressable,
	useWindowDimensions,
} from "react-native";
import { Ionicons, MaterialCommunityIcons, Feather } from "@expo/vector-icons";
import DrawerLayout from "react-native-gesture-handler/DrawerLayout";
import RNPickerSelect from "react-native-picker-select";
import Slider from "@react-native-community/slider";

import * as Animatable from "react-native-animatable";
import { useEffect } from "react";

import algoliasearch from "algoliasearch/lite";
import { useNavigation } from "@react-navigation/native";
import { StyledText } from "../components/StyledText";

import firebase, { firestore } from "firebase/app";
import { Helmet } from "react-helmet";
import LinkButton from "../components/LinkButton";
import Header from "../components/Header";
import HTML from "react-native-render-html";
import Footer from "../components/Footer";
import GrowingElement from "../components/GrowingElement";
import DownloadBlock from "../components/DownloadBlock";
import { TouchableHighlight, TouchableWithoutFeedback } from "react-native-gesture-handler";

const client = algoliasearch("FPUF7P23Y7", "4096d8d6f649f05502ca9e678e4c677a");
const index = client.initIndex("stores");


const RoutesScreen = ({ route }) => {
	const navigation = useNavigation();
	const SW = useWindowDimensions().width
	const SH = useWindowDimensions().height
	const scrollRef = useRef();

	const [claimBusiness, setClaimBusiness] = useState(false);
	const [data, setData] = useState([]);
	const [success, setSuccess] = useState(false);
	const [loading, setLoading] = useState(false);
	const [stores, setStores] = useState([]);
	const [showStoresModal, setShowStoresModal] = useState(false);
	const [search, setSearch] = useState("");
	const [searchFocus, setSearchFocus] = useState(false);

	useEffect(() => {
		fetchData();
	}, []);

	const fetchData = async () => {
		firebase
			.firestore()
			.collection("routes")
			.get()
			.then((q) => {
				const r = q.docs.map((el) => ({ ...el.data(), id: el.id, ref: el.ref }));
				setData(r);
			})
			.catch((err) => console.log(err));
		// id &&
		// 	firebase
		// 		.firestore()
		// 		.collection("pages")
		// 		.doc(id)
		// 		.get()
		// 		.then((doc) => {
		// 			setData({ ...doc.data(), id: doc.id });
		// 		})
		// 		.catch((err) => console.log(err));
	};

	return (
		<>
			<ScrollView ref={scrollRef} style={styles.container} contentContainerStyle={{ flexGrow: 1 }}>
				{Platform.OS === "web" && (
					<Helmet>
						<meta charSet="utf-8" />
						<title>{"Participa en el Retail Hub Madrid - El comercio de Proximidad de Madrid"}</title>
						<link rel="canonical" href="https://reatilhubmadrid.web.app/home" />
					</Helmet>
				)}

				<View style={{ width: "100%", paddingVertical: 100, alignItems: "center" }}>
					<Image
						style={{ position: "absolute", top: 0, left: 0, bottom: 0, right: 0, zIndex: 1, resizeMode: "cover", height: 500 }}
						source={{ uri: "https://images.pexels.com/photos/3699657/pexels-photo-3699657.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260" }}
					/>
					<View style={{ position: "absolute", top: 0, left: 0, bottom: 0, right: 0, zIndex: 2, backgroundColor: "rgba(10,10,10,.6)" }} />
					<Animatable.View animation="fadeInUp" style={{ zIndex: 3, paddingHorizontal: 10, alignItems: "center", maxWidth: 600, justifyContent: "center", flex: 1 }}>
						<StyledText style={{ color: "white", fontSize: 44, fontWeight: "700", textAlign: "center" }}>Rutas</StyledText>
						<View style={{ maxWidth: 600, marginBottom: 30, alignSelf: "center", alignItems: "center" }}>
							<StyledText style={{ marginBottom: 0, fontSize: 18, color: "white", marginTop: 20, textAlign: "center" }}>
								Descubre rutas por las que perderte en Madrid desde compras exclusivas a descubrir lugares secretos.
							</StyledText>
						</View>

						<View style={styles.searchContainer}>
							<View style={[styles.searchBar, styles.shadow, { transitionDuration: "150ms", transform: [{ scale: searchFocus ? 1.02 : 1 }] }]}>
								<TextInput
									placeholder={`Escribe qué estás buscando...`}
									onFocus={() => setSearchFocus(true)}
									onBlur={() => setSearchFocus(false)}
									// onSubmitEditing={() => navigation.navigate("Search", { screen: "Map", params: { searchQuery: search } })}
									onChangeText={(ev) => setSearch(ev)}
									style={{ fontSize: 16, flex: 1, paddingLeft: 20, paddingRight: 50 }}
								/>
								<TouchableOpacity style={{ position: "absolute", right: 0, height: 50, width: 50, alignItems: "center", justifyContent: "center" }}>
									<Ionicons name="search" size={25} color="#4C4C4C" />
								</TouchableOpacity>
							</View>
						</View>
					</Animatable.View>
				</View>

				<View style={{ flex: 1, backgroundColor: "white", alignItems: "center", flexDirection: "row", flexWrap: "wrap", padding: 50 }}>
					{data.filter((el) => el.name.toLocaleLowerCase().includes(search.toLocaleLowerCase())).length > 0 ? (
						data
							.filter((el) => el.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()))
							.map((item, i) => {
								return (
									<GrowingElement
										key={i}
										opacity={false}
										style={{
											backgroundColor: "gainsboro",
											borderRadius: 7,
											height: 200,
											margin: SW < 600 ? 10 : SW < 900 ? "1%" : "0.666666%",
											flexBasis: SW < 600 ? "100%" : SW < 900 ? "48%" : "32%",
											borderRadius: 8,
											overflow: "hidden",
										}}
										onPress={() => navigation.navigate("Routes", {screen: "RouteMap", params: {id: item.id}})}
									>
										<Image
											style={{ flex: 1, position: "absolute", top: 0, left: 0, right: 0, bottom: 0, zIndex: 1 }}
											source={{ uri: item.image || "https://images.pexels.com/photos/17658/pexels-photo.jpg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260" }}
										/>
										<View style={{ flex: 1, position: "absolute", top: 0, left: 0, right: 0, bottom: 0, backgroundColor: "rgba(0,0,0,.6)", zIndex: 2 }} />
										<View style={{ zIndex: 3, flex: 1, padding: 15, paddingHorizontal: 20, justifyContent: "space-between" }}>
											<StyledText numberOfLines={3} style={{ fontSize: 24, color: "white" }}>
												{item.name}
											</StyledText>
											<StyledText numberOfLines={3} style={{ fontSize: 15, color: "white" }}>
												{item.steps.length} paradas - {Math.round(item.time / 60)} horas
											</StyledText>
										</View>
									</GrowingElement>
								);
							})
					) : (
						<View style={{ width: "100%", maxWidth: 600, alignItems: "center", justifyContent: "center", backgroundColor: "#f3f3f3", borderRadius: 15, height: 400 }}>
							<StyledText>No hay rutas disponibles</StyledText>
						</View>
					)}
					
				</View>

				<DownloadBlock />
				<Footer />
			</ScrollView>

			{showStoresModal && (
				<Modal
					visible={showStoresModal}
					presentationStyle="overFullScreen"
					hasBackdrop={true}
					coverScreen={true}
					style={{ position: "fixed", zIndex: 30, height: SH, width: SW, alignItems: "center", justifyContent: "center", borderColor: "transparent", borderWidth: 0 }}
				>
					<Pressable onPress={() => setShowStoresModal(false)} style={{ position: "absolute", width: "100%", height: "100%", zIndex: 1 }}>
						<View style={{ backgroundColor: "rgba(0,0,0,.8)", flex: 1 }} />
					</Pressable>

					<View style={{ position: "relative", zIndex: 2, padding: 15, borderRadius: 10, backgroundColor: "white", maxHeight: 600 }}>
						<View style={{ flexDirection: "row", alignItems: "center" }}>
							<View style={{ flex: 1 }}>
								<StyledText style={{ fontSize: 24, marginBottom: 10, fontWeight: "700", textAlign: "center" }}>Selecciona tu negocio para reclamar su titularidad</StyledText>
							</View>
						</View>
						<View style={{ height: 40, borderRadius: 25, margin: 10, borderWidth: 1 }}>
							<TextInput style={{ paddingHorizontal: 10, flex: 1 }} placeholder="Nombre y dirección..." />
						</View>
						<ScrollView>
							<View style={{ padding: 15, alignItems: "center", maxWidth: 450, width: "100%", marginHorizontal: 10 }}>
								{stores.length === 0 ? (
									<StyledText>No hemos encontrado ninguna tienda con esta busqueda</StyledText>
								) : (
									stores.map((store, i) => {
										return (
											<View key={i} style={{ paddingHorizontal: 10, paddingVertical: 10, width: "100%" }}>
												<StyledText style={{ fontSize: 16, fontWeight: "700" }} key={i}>
													{store.name}
												</StyledText>
												<StyledText style={{ fontSize: 12 }} key={i}>
													{store.fullAddress}
												</StyledText>
											</View>
										);
									})
								)}
							</View>
						</ScrollView>
					</View>
				</Modal>
			)}
		</>
	);
};

export default RoutesScreen;

const styles = StyleSheet.create({
	container: {
		flex: 1,
		zIndex: 1,
		position: "relative",
	},
	header: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		paddingLeft: 15,
		paddingRight: 5,
		paddingBottom: 30,
		backgroundColor: "#FFC148",
		width: "100%",
	},
	logo: {
		height: 40,
		width: 150,
		marginBottom: 5,
	},
	searchBackground: {
		height: 25,
		backgroundColor: "#FFC148",
	},
	searchContainer: {
		flexDirection: "row",
		justifyContent: "space-between",
		height: 50,
		width: "100%",
		maxWidth: 600,
		marginBottom: 0,
		marginTop: -25,
		zIndex: 10,
	},
	searchBar: {
		width: "100%",
		height: 50,
		maxWidth: 600,
		marginTop: 20,
		borderRadius: 25,
		backgroundColor: "white",
	},
	searchFilterButton: {
		width: 60,
		height: 50,
		justifyContent: "center",
		borderTopLeftRadius: 25,
		borderBottomLeftRadius: 25,
		paddingLeft: 15,
		backgroundColor: "white",
	},
	map: {
		zIndex: 1,
		flex: 1,
		marginTop: -25,
		height: "100%",
		width: "100%",
	},

	shadow: {
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 6,
		},
		shadowOpacity: 0.15,
		shadowRadius: 8.3,

		elevation: 13,
	},

	sideShadow: {
		shadowColor: "#000",
		shadowOffset: {
			width: 5,
			height: 0,
		},
		shadowOpacity: 0.15,
		shadowRadius: 10,

		elevation: 13,
	},
});
