import React from "react";

import HomeScreen from "../screens/HomeScreen";
import PageScreen from "../screens/PageScreen";
import RegisterScreen from "../screens/RegisterScreen";
import SearchStack from "../navigators/SearchStack";
import PromotionsStack from "../navigators/PromotionsStack";
import ProfileStack from "../navigators/ProfileStack";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";

import Header from "../components/Header";
import BottomTabBar from "../components/BottomTabBar";
import { useNavigationState } from "@react-navigation/native";
import { Platform } from "react-native";
import FeaturedScreen from "../screens/FeaturedScreen";
import RoutesScreen from "../screens/RoutesScreen";
import RoutesStack from "./RoutesStack";

const Tab = createBottomTabNavigator();

const MainTabNavigator = () => {
	// const index = useNavigationState((state) => state.index);
	return (
		<>
			{/* <Header index={0} /> */}
			<Tab.Navigator tabBar={(props) => (Platform.OS === "web" ? null : <BottomTabBar {...props} />)}>
				<Tab.Screen name="Home" component={HomeScreen} />
				<Tab.Screen name="Search" component={SearchStack} />
				<Tab.Screen name="Featured" component={FeaturedScreen} />
				<Tab.Screen name="Routes" component={RoutesStack} />
				<Tab.Screen name="Profile" component={ProfileStack} />
				<Tab.Screen name="Page" component={PageScreen} />
				<Tab.Screen name="Register" component={RegisterScreen} />
			</Tab.Navigator>
		</>
	);
};

export default MainTabNavigator;
