import React from "react";
import { Image, View, StyleSheet, TouchableOpacity, Dimensions } from "react-native";
import { StyledText } from "../components/StyledText";
import * as Animatable from "react-native-animatable";
import LinkButton from "./LinkButton";

const NewsVerticalCard = ({ title, price, image, index, width = 200, to, action }) => {
	return (
		<Animatable.View animation="fadeInUp" duration={300} delay={100 * index}>
			<LinkButton to={to} action={action}>
				<View style={[styles.card, styles.shadow, { width: width }]}>
					<Image source={{ uri: image }} style={styles.productImage} />
					<View style={styles.cardBody}>
						<StyledText numberOfLines={2} truncate style={styles.productTitle}>
							{title}
						</StyledText>
					</View>
				</View>
			</LinkButton>
		</Animatable.View>
	);
};

export default NewsVerticalCard;

const styles = StyleSheet.create({
	productImage: {
		width: "100%",
		height: 170,
		borderRadius: 10,
	},
	card: {
		height: 280,
		borderRadius: 15,
		backgroundColor: "white",
		margin: 5,
	},
	cardBody: {
		flex: 1,
		padding: 10,
		justifyContent: "space-between",
	},
	productTitle: {
		fontSize: 16,
	},
	price: {
		fontSize: 22,
		fontWeight: "700",
	},
	priceContainer: {
		alignItems: "flex-end",
	},
	shadow: {
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 6,
		},
		shadowOpacity: 0.15,
		shadowRadius: 8.3,

		elevation: 13,
	},
});
