import React from "react";
import { View, Image, TouchableOpacity, Dimensions } from "react-native";
import LinkButton from "./LinkButton";
import { StyledText } from "./StyledText";

const SW = Dimensions.get("window").width
const DownloadBlock = () => {
	return (
		<View style={{ backgroundColor: "#ffc148", height: SW < 800 ? 700 : 500, width: "100%", paddingHorizontal: 10, justifyContent: "center" }}>
			<View style={{ maxWidth: 1080, width: "100%", height: "100%", flexDirection: SW < 800 ? "column" : "row", alignSelf: "center" }}>
				<View style={{ flex: 1, justifyContent: "center" }}>
					<View style={{ width: "100%" }}>
						<StyledText style={{ fontSize: 34, marginBottom: 10, fontWeight: "700", textAlign: SW < 800 ? "center" : "left" }}>Únete a RetailHub,</StyledText>
						<StyledText style={{ fontSize: 34, marginBottom: 10, fontWeight: "700", textAlign: SW < 800 ? "center" : "left" }}>Descarga la App en tu móvil!</StyledText>
					</View>
					<View style={{ flexDirection: "row", width: "100%", marginTop: 15, justifyContent: "flex-start", alignItems: SW < 800 ? "flex-start" : "flex-start" }}>
						<LinkButton opacity={false}>
							<TouchableOpacity style={{ marginRight: 10, minWidth: 150, width: SW < 800 ? "50%" : 150, borderRadius: 5, overflow:"hidden" }}>
								<Image style={{ height: 45, width: "100%", resizeMode: "contain" }} source={{ uri: "https://upload.wikimedia.org/wikipedia/commons/thumb/7/78/Google_Play_Store_badge_EN.svg/1200px-Google_Play_Store_badge_EN.svg.png" }} />
							</TouchableOpacity>
						</LinkButton>
						<LinkButton opacity={false}>
							<TouchableOpacity style={{ marginRight: 10, minWidth: 150, width: SW < 800 ? "50%" : 150, borderRadius: 5, overflow:"hidden" }}>
								<Image style={{ height: 45, width: "100%", resizeMode: "contain" }} source={{ uri: "https://freepikpsd.com/media/2019/10/download-app-store-png-4-Transparent-Images.png" }} />
							</TouchableOpacity>
						</LinkButton>
					</View>
				</View>
				<View style={{ flex: 1, height: "100%", marginTop: 20 }}>
					<Image style={{ borderRadius: 15, flex: 1, height: "100%", resizeMode: SW < 800 ? "contain" : "cover", width: "100%" }} source={require("../assets/phones.png")} />
				</View>
			</View>
		</View>
	);
};

export default DownloadBlock;
