import React, { useRef, useState } from "react";
// import MapView, { Circle, Marker } from "react-native-maps";
import {
	StyleSheet,
	Text,
	Image,
	View,
	ScrollView,
	FlatList,
	TouchableOpacity,
	Platform,
	TextInput,
	Dimensions,
	ActivityIndicator,
	Modal,
	Touchable,
	Pressable,
} from "react-native";
import { Ionicons, MaterialCommunityIcons, Feather } from "@expo/vector-icons";
import DrawerLayout from "react-native-gesture-handler/DrawerLayout";
import RNPickerSelect from "react-native-picker-select";
import Slider from "@react-native-community/slider";

import * as Animatable from "react-native-animatable";
import { useEffect } from "react";

import algoliasearch from "algoliasearch/lite";
import { StackActions, useNavigation } from "@react-navigation/native";
import { StyledText } from "../components/StyledText";

import firebase, { firestore } from "firebase/app";
import { Helmet } from "react-helmet";
import LinkButton from "../components/LinkButton";
import Header from "../components/Header";
import HTML from "react-native-render-html";
import Footer from "../components/Footer";
import GrowingElement from "../components/GrowingElement";
import DownloadBlock from "../components/DownloadBlock";
import { TouchableHighlight, TouchableWithoutFeedback } from "react-native-gesture-handler";

const client = algoliasearch("FPUF7P23Y7", "4096d8d6f649f05502ca9e678e4c677a");
const index = client.initIndex("stores");

const SW = Dimensions.get("window").width;
const SH = Dimensions.get("window").height;

const FeaturedScreen = ({ route }) => {
	const navigation = useNavigation();

	const scrollRef = useRef();

	const [success, setSuccess] = useState(false);
	const [loading, setLoading] = useState(false);
	const [stores, setStores] = useState([]);
	const [error, setError] = useState(false);

	useEffect(() => {
		fetchData();
	}, []);

	const fetchData = async () => {
		firebase
			.firestore()
			.collection("pages")
			.doc("Wfi4505FuR1XZsBZNM0p")
			.get()
			.then(async (doc) => {
				const d = await Promise.all(doc.data().featured.map(store => {
					const e = store.get().then(res => ({ ...res.data(), id: res.id }))
					return e
				}))
				setStores(d);
			})
			.catch((err) => console.log(err));
	};






	return (
		<>
			<ScrollView ref={scrollRef} style={styles.container} contentContainerStyle={{ flexGrow: 1 }}>
				{Platform.OS === "web" && (
					<Helmet>
						<meta charSet="utf-8" />
						<title>{"Comercios destacados - El comercio de Proximidad de Madrid"}</title>
						<link rel="canonical" href="https://retailhub.es/featured" />
					</Helmet>
				)}


				<View style={{ width: "100%", paddingVertical: 100, height: 300, alignItems: "center" }}>
					<Image style={{ position: "absolute", top: 0, left: 0, bottom: 0, right: 0, zIndex: 1, resizeMode: "cover", height: 500, }} source={{ uri: "https://images.pexels.com/photos/17658/pexels-photo.jpg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260" }} />
					<View style={{ position: "absolute", top: 0, left: 0, bottom: 0, right: 0, zIndex: 2, backgroundColor: "rgba(10,10,10,.6)" }} />
					<Animatable.View animation="fadeInUp" style={{ zIndex: 3, paddingHorizontal: 10, alignItems: "center", maxWidth: 600, justifyContent: "center", flex: 1 }}>
						<StyledText style={{ color: "white", fontSize: 44, fontWeight: "700", textAlign: "center" }}>Comercios Destacados</StyledText>
						<View style={{ maxWidth: 600, alignSelf: "center", alignItems: "center" }}>
							<StyledText style={{ marginBottom: 0, fontSize: 18, color: "white", marginTop: 20, textAlign: "center" }}>
								Negocios destacados cerca de tí. Conoce nuevos sitios que visitar y realizar tus compras en Madrid
							</StyledText>
						</View>
					</Animatable.View>
				</View>

				<View style={{ flex: 1, paddingHorizontal: 15, backgroundColor: "white", alignItems: "center", paddingVertical: 80 }}>

					<View style={{ flexDirection: "row", maxWidth: 1200, justifyContent: "flex-start", width: "100%" }}>
						<GrowingElement onPress={() => navigation.navigate("Search", { screen: "Store", params: { id: stores[0].id } })} opacity={false} style={{ flex: 2, margin: 5 }}>
							<View style={{ zIndex: 2, position: "absolute", top: 0, bottom: 0, left: 0, right: 0, backgroundColor: "rgba(0,0,0,.4)", borderRadius: 10 }} />
							<Image source={{ uri: stores[0]?.images[4] }} style={{ zIndex: 1, position: "absolute", flex: 1, top: 0, bottom: 0, left: 0, right: 0, resizeMode: "cover", borderRadius: 10 }} />
							<View style={{ zIndex: 3, padding: 15, flex: 1, justifyContent: "flex-end" }}>
								<StyledText style={{ color: "white", fontSize: 24, fontWeight: "700" }}>{stores[0]?.name}</StyledText>
							</View>
						</GrowingElement>
						<View style={{ flex: 3 }}>
							{stores.map((store, i) => {
								if (i > 0 && i < 4) {
									return (
										<GrowingElement key={i} onPress={() => navigation.navigate("Search", { screen: "Store", params: { id: store.id } })} opacity={false} style={{ height: SH / 4, minHeight: 180, flex: 1, margin: 5 }}>
											<View style={{ zIndex: 2, position: "absolute", top: 0, bottom: 0, left: 0, right: 0, backgroundColor: "rgba(0,0,0,.4)", borderRadius: 10 }} />
											<Image source={{ uri: store?.images[0] }} style={{ zIndex: 1, position: "absolute", flex: 1, top: 0, bottom: 0, left: 0, right: 0, resizeMode: "cover", borderRadius: 10 }} />
											<View style={{ zIndex: 3, padding: 15, flex: 1, justifyContent: "flex-end" }}>
												<StyledText style={{ color: "white", fontSize: 24, fontWeight: "700" }}>{store?.name}</StyledText>
											</View>
										</GrowingElement>
									)
								}
							})}

						</View>
					</View>

					<StyledText style={{ fontSize: 34, marginBottom: 10, marginTop: 80, fontWeight: "700", textAlign: "center" }}>Negocios Innovadores</StyledText>

					<View style={{ flexDirection: "row", marginTop: 50, maxWidth: 1200, justifyContent: "flex-start", width: "100%" }}>
						<View style={{ flex: 2 }}>
							<StyledText style={{ fontSize: 28, fontWeight: "700" }}>{stores[4]?.name}</StyledText>
							<HTML source={{ html: stores[4]?.description ? stores[4]?.description.substring(0, 1000) : "<p/>" }} contentWidth={600} />
							
								<GrowingElement onPress={() => navigation.navigate("Search", { screen: "Store", params: { id: stores[4]?.id } })} opacity={false} style={{ backgroundColor: "#FFC148", borderRadius: 5, height: 40, width: 200, paddingHorizontal: 10, alignItems: "center", justifyContent: "center" }}>
									<StyledText style={{ color: "white", fontWeight: "bold" }}>Ver Negocio</StyledText>
								</GrowingElement>

						</View>
						<View style={{ flex: 2, marginLeft: 25 }}>
							<Image source={{ uri: stores[4]?.images[6] }} style={{ zIndex: 1, position: "absolute", flex: 1, top: 0, bottom: 0, left: 0, right: 0, resizeMode: "cover", borderRadius: 10 }} />
						</View>

					</View>

					<StyledText style={{ fontSize: 34, marginBottom: 10, marginTop: 80, fontWeight: "700", textAlign: "center" }}>Categorías Destacadas</StyledText>
					<View
						style={{
							flexDirection: "row",
							flexWrap: "wrap",
							maxWidth: 1200,
							width: "100%",
							alignSelf: "center",
							justifyContent: "center",
							alignItems: "center",
							paddingVertical: 50,
						}}
					>
						<GrowingElement opacity={false} style={{ minWidth: 250, height: SH / 5, flex: 1, margin: 5 }}>
							<View style={{ zIndex: 2, position: "absolute", top: 0, bottom: 0, left: 0, right: 0, backgroundColor: "rgba(0,0,0,.5)", borderRadius: 10 }} />
							<Image source={{ uri: "https://images.pexels.com/photos/1109197/pexels-photo-1109197.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500" }} style={{ zIndex: 1, position: "absolute", flex: 1, top: 0, bottom: 0, left: 0, right: 0, resizeMode: "cover", borderRadius: 10 }} />
							<View style={{ zIndex: 3, padding: 15, flex: 1, justifyContent: "flex-end" }}>
								<StyledText style={{ color: "white", fontSize: 24, fontWeight: "700" }}>Alimentación</StyledText>
							</View>
						</GrowingElement>
						<GrowingElement opacity={false} style={{ minWidth: 250, height: SH / 5, flex: 1, margin: 5 }}>
							<View style={{ zIndex: 2, position: "absolute", top: 0, bottom: 0, left: 0, right: 0, backgroundColor: "rgba(0,0,0,.5)", borderRadius: 10 }} />
							<Image source={{ uri: "https://images.pexels.com/photos/2663851/pexels-photo-2663851.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500" }} style={{ zIndex: 1, position: "absolute", flex: 1, top: 0, bottom: 0, left: 0, right: 0, resizeMode: "cover", borderRadius: 10 }} />
							<View style={{ zIndex: 3, padding: 15, flex: 1, justifyContent: "flex-end" }}>
								<StyledText style={{ color: "white", fontSize: 24, fontWeight: "700" }}>Libros</StyledText>
							</View>
						</GrowingElement>
						<GrowingElement opacity={false} style={{ minWidth: 250, height: SH / 5, flex: 1, margin: 5 }}>
							<View style={{ zIndex: 2, position: "absolute", top: 0, bottom: 0, left: 0, right: 0, backgroundColor: "rgba(0,0,0,.5)", borderRadius: 10 }} />
							<Image source={{ uri: "https://images.pexels.com/photos/380782/pexels-photo-380782.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500" }} style={{ zIndex: 1, position: "absolute", flex: 1, top: 0, bottom: 0, left: 0, right: 0, resizeMode: "cover", borderRadius: 10 }} />
							<View style={{ zIndex: 3, padding: 15, flex: 1, justifyContent: "flex-end" }}>
								<StyledText style={{ color: "white", fontSize: 24, fontWeight: "700" }}>Lujo</StyledText>
							</View>
						</GrowingElement>
						<GrowingElement opacity={false} style={{ minWidth: 250, height: SH / 5, flex: 1, margin: 5 }}>
							<View style={{ zIndex: 2, position: "absolute", top: 0, bottom: 0, left: 0, right: 0, backgroundColor: "rgba(0,0,0,.5)", borderRadius: 10 }} />
							<Image source={{ uri: "https://images.pexels.com/photos/1670770/pexels-photo-1670770.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260" }} style={{ zIndex: 1, position: "absolute", flex: 1, top: 0, bottom: 0, left: 0, right: 0, resizeMode: "cover", borderRadius: 10 }} />
							<View style={{ zIndex: 3, padding: 15, flex: 1, justifyContent: "flex-end" }}>
								<StyledText style={{ color: "white", fontSize: 24, fontWeight: "700" }}>Moda</StyledText>
							</View>
						</GrowingElement>
						<GrowingElement opacity={false} style={{ minWidth: 250, height: SH / 5, flex: 1, margin: 5 }}>
							<View style={{ zIndex: 2, position: "absolute", top: 0, bottom: 0, left: 0, right: 0, backgroundColor: "rgba(0,0,0,.5)", borderRadius: 10 }} />
							<Image source={{ uri: "https://images.pexels.com/photos/1779487/pexels-photo-1779487.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260" }} style={{ zIndex: 1, position: "absolute", flex: 1, top: 0, bottom: 0, left: 0, right: 0, resizeMode: "cover", borderRadius: 10 }} />
							<View style={{ zIndex: 3, padding: 15, flex: 1, justifyContent: "flex-end" }}>
								<StyledText style={{ color: "white", fontSize: 24, fontWeight: "700" }}>Tecnología</StyledText>
							</View>
						</GrowingElement>
						<GrowingElement opacity={false} style={{ minWidth: 250, height: SH / 5, flex: 1, margin: 5 }}>
							<View style={{ zIndex: 2, position: "absolute", top: 0, bottom: 0, left: 0, right: 0, backgroundColor: "rgba(0,0,0,.5)", borderRadius: 10 }} />
							<Image source={{ uri: "https://images.pexels.com/photos/3201920/pexels-photo-3201920.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260" }} style={{ zIndex: 1, position: "absolute", flex: 1, top: 0, bottom: 0, left: 0, right: 0, resizeMode: "cover", borderRadius: 10 }} />
							<View style={{ zIndex: 3, padding: 15, flex: 1, justifyContent: "flex-end" }}>
								<StyledText style={{ color: "white", fontSize: 24, fontWeight: "700" }}>Ocio</StyledText>
							</View>
						</GrowingElement>
					</View>
				</View>


				<DownloadBlock />
				<Footer />
			</ScrollView>

		</>
	);
};

export default FeaturedScreen;

const styles = StyleSheet.create({
	container: {
		flex: 1,
		zIndex: 1,
		position: "relative",
	},
	header: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		paddingLeft: 15,
		paddingRight: 5,
		paddingBottom: 30,
		backgroundColor: "#FFC148",
		width: "100%",
	},
	logo: {
		height: 40,
		width: 150,
		marginBottom: 5,
	},
	searchBackground: {
		height: 25,
		backgroundColor: "#FFC148",
	},
	searchContainer: {
		flexDirection: "row",
		justifyContent: "space-between",
		height: 50,
		marginBottom: 0,
		marginTop: -25,
		zIndex: 10,
	},
	searchBar: {
		width: "100%",
		height: 50,
		maxWidth: 600,
		marginTop: 20,
		borderRadius: 25,
		backgroundColor: "white",
	},
	searchFilterButton: {
		width: 60,
		height: 50,
		justifyContent: "center",
		borderTopLeftRadius: 25,
		borderBottomLeftRadius: 25,
		paddingLeft: 15,
		backgroundColor: "white",
	},
	map: {
		zIndex: 1,
		flex: 1,
		marginTop: -25,
		height: "100%",
		width: "100%",
	},

	shadow: {
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 6,
		},
		shadowOpacity: 0.15,
		shadowRadius: 8.3,

		elevation: 13,
	},

	sideShadow: {
		shadowColor: "#000",
		shadowOffset: {
			width: 5,
			height: 0,
		},
		shadowOpacity: 0.15,
		shadowRadius: 10,

		elevation: 13,
	},
});
