import React, { useEffect, useState, useRef } from "react";
import { View, StyleSheet, Platform, Image } from "react-native";

let MapView, Marker, Circle;
if (Platform.OS === "web") {
	MapView = require("react-native-web-maps").default;
	Marker = require("react-native-web-maps").default.Marker;
	Circle = require("react-native-web-maps").default.Circle;
} else {
	MapView = require("react-native-map-clustering").default;
	Marker = require("react-native-maps").Marker;
	Circle = require("react-native-maps").Circle;
}

const Map = ({ results = [], location, distance = 1000, onPress, dragCallback, itemSelected }) => {

	const [dragging, setDragging ] = useState(false)
	const mapRef = useRef();
	const mapStyle = [
		{
			featureType: "administrative",
			elementType: "labels",
			// stylers: [{ visibility: "off" }],
		},
		{
			featureType: "poi",
			elementType: "labels",
			stylers: [{ visibility: "off" }],
		},
		{
			featureType: "water",
			elementType: "labels",
			stylers: [{ visibility: "off" }],
		},
		{
			featureType: "road",
			elementType: "labels",
			// stylers: [{ visibility: "off" }],
		},
		{
			featureType: "landscape",
			elementType: "labels",
			stylers: [
				{
					visibility: "off",
				},
			],
		},
	];

	const mapStyleNative = [
		{
			elementType: "labels",
			stylers: [
				{
					visibility: "off",
				},
			],
		},
		{
			featureType: "transit",
			elementType: "labels",
			stylers: [{ visibility: "on" }],
		},
		{
			featureType: "road",
			elementType: "labels",
			stylers: [{ visibility: "on" }],
		},
		{
			featureType: "administrative",
			elementType: "labels",
			stylers: [
				{
					visibility: "on",
				},
			],
		},
	];

	useEffect(() => {
		// location && Platform.OS === "web" && mapRef.current.setCenter({
		// 	lat: location.coords.latitude,
		// 	lng: location.coords.longitude
		// });
	}, [location]);

	return (
		<MapView
			ref={mapRef}
			showsBuildings={true}
			minZoomLevel={5}
			pitchEnabled={false}
			showsUserLocation={true}
			showsUserLocation={true}
			scrollEnabled={Platform.OS === "android" ? dragging ? false : true : true}
			
			// onMarkerDragStart={() => console.log("PRESSING")}
			// onPanDrag={() => console.log("DD")}
			// onPress={({latLng})=>console.log(latLng.lat(), latLng.lng())}
			// onLongPress={() => console.log('LONG')}
			// onMapLongClick={() => console.log('LONG')}
			initialRegion={{
				latitude: 40.4110238913975,
				longitude: -3.69678454980386,
				latitudeDelta: 0.0922,
				longitudeDelta: 0.0421,
			}}
			showsPointsOfInterest={false}
			showsCompass={false}
			showsScale={false}
			toolbarEnabled={false}
			// showsUserLocation={true}
			// region={{
			// 	latitude: location ? location.coords.latitude : 40.4110238913975,
			// 	longitude: location ? location.coords.longitude : -3.69678454980386,
			// 	latitudeDelta: 0.0922,
			// 	longitudeDelta: 0.0421,
			// }}

			spiralEnabled={true}
			clusterColor="#ffc148"
			clickableIcons={false}
			mapType="mutedStandard"
			options={{
				mapTypeControl: false,
				streetViewControl: false,
				fullscreenControl: false,
				backgroundColor: "#fefefe",
				styles: mapStyle,
			}}
			customMapStyle={mapStyleNative}
			style={styles.map}
		>
			{location && (
				<Marker
					draggable={true}
					zIndex={30}
					cluster = {false}
					// centerOffset={Platform.OS === "android" ? { x: -50, y: -10 } : { x: 0, y: 0 }}
					// onDragStart={() => console.log("aaaPRESSING")}
					// onDragEnd={({ latLng: { lat, lng } }) => dragCallback(lat(), lng())}
					onLongPress={() => setDragging(true)}
					onDragEnd={
						Platform.OS === "web"
							? ({ latLng: { lat, lng } }) => {
								dragCallback(lat(), lng())
								setDragging(false)
							}
							: ({nativeEvent: {coordinate: { latitude, longitude }}}) => {
								dragCallback(latitude, longitude)
								setDragging(false)
							}
					}
					icon={{ url: "./mp.png", anchor: Platform.OS === "android" ? { x: 40, y: -10 } : { x: 10, y: 10 }, scaledSize: { width: 20, height: 20 } }}
					coordinate={{
						latitude: location.coords.latitude,
						longitude: location.coords.longitude,
					}}
				>
					<View
						hitSlop={{ top: 20, left: 20, bottom: 20, right: 20 }}
						style={{ width: 25, height: 25, backgroundColor: "white", borderRadius: 17, borderWidth: 4, borderColor: "#FFC148" }}
					/>
				</Marker>
			)}

			{results.map((item, i) =>
				Array.isArray(item._geoloc) ? (
					item._geoloc.map((product, dex) => (
						<Marker
							key={dex}
							zIndex={itemSelected?.objectID === item.objectID ? 15 : 5}
							icon={{
								url: product.mapIcon ? product.mapIcon : "https://cdn0.iconfinder.com/data/icons/small-n-flat/24/678111-map-marker-512.png",
								scaledSize: { width: itemSelected?.objectID === item.objectID ? 45 : 35, height: itemSelected?.objectID === item.objectID ? 45 : 35 },
							}}
							onPress={() => onPress(item)}
							coordinate={{
								latitude: parseFloat(product?.lat),
								longitude: parseFloat(product?.lng),
							}}
						>
							<Image
								style={{ height: 35, width: 35, transform: Platform.OS !== "android" ? [{ scale: itemSelected?.objectID === item.objectID ? 1.5 : 1 }] : [] }}
								source={{ uri: product.mapIcon ? product.mapIcon : "https://cdn0.iconfinder.com/data/icons/small-n-flat/24/678111-map-marker-512.png" }}
							/>
						</Marker>
					))
				) : (
					<Marker
						key={i}
						zIndex={itemSelected?.objectID === item.objectID ? 15 : 5}
						icon={{
							url: item.mapIcon ? item.mapIcon : "https://cdn0.iconfinder.com/data/icons/small-n-flat/24/678111-map-marker-512.png",
							scaledSize: { width: itemSelected?.objectID === item.objectID ? 45 : 35, height: itemSelected?.objectID === item.objectID ? 45 : 35 },
						}}
						onPress={() => onPress(item)}
						// style={{ backgroundColor: "orange", height: itemSelected?.objectID === item.objectID ? 50 : 30, width: itemSelected?.objectID === item.objectID ? 50 : 30 }}
						coordinate={{
							latitude: parseFloat(item?._geoloc?.lat),
							longitude: parseFloat(item?._geoloc?.lng),
						}}
					>
						<Image
							style={{ height: 35, width: 35, transform: Platform.OS !== "android" ? [{ scale: itemSelected?.objectID === item.objectID ? 1.5 : 1 }] : [] }}
							source={{ uri: item.mapIcon ? item.mapIcon : "https://cdn0.iconfinder.com/data/icons/small-n-flat/24/678111-map-marker-512.png" }}
						/>
					</Marker>
				)
			)}

			{location && (
				<Circle
					radius={distance}
					defaultCenter={{
						lat: location.coords.latitude,
						lng: location.coords.longitude,
					}}
					fillColor="rgba(255, 193, 72, .3)"
					strokeColor="transparent"
					options={{
						fillColor: "#ffc148",
						strokeColor: "#5541ff",
						fillOpacity: 0.2,
						strokeOpacity: 0.0,
					}}
					zIndex={1}
					center={{
						lat: location.coords.latitude,
						lng: location.coords.longitude,
						latitude: location.coords.latitude,
						longitude: location.coords.longitude,
					}}
				/>
			)}
		</MapView>
	);
};

export default Map;

const styles = StyleSheet.create({
	map: {
		zIndex: 1,
		flex: 1,
		marginTop: -25,
		height: "100%",
		width: "100%",
	},

	shadow: {
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 6,
		},
		shadowOpacity: 0.15,
		shadowRadius: 8.3,

		elevation: 13,
	},
});
