import React, { useState, useRef } from "react";
import { View, Image, TouchableOpacity, Linking, StyleSheet, Dimensions, Platform, TouchableWithoutFeedback } from "react-native";
import { Ionicons, MaterialCommunityIcons, Feather } from "@expo/vector-icons";

import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useNavigation, StackActions } from "@react-navigation/native";
import { StyledText } from "../components/StyledText";
import LinkButton from "./LinkButton";
import { DrawerLayout } from "react-native-gesture-handler";
import * as Animatable from "react-native-animatable";
import { useContext } from "react";
import AuthContext from "../context/AuthContext";

const Footer = ({ index }) => {
	const navigation = useNavigation();
	const { pages } = useContext(AuthContext);

	return (
		<View style={{ backgroundColor: "#f3f3f3", alignItems: "center", paddingBottom: 10, paddingTop:15 }}>
			<View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between", maxWidth: 600, height: 60, width: "100%", paddingHorizontal: 10 }}>
				<Image style={{ resizeMode: "contain", flex: 1, height: 50, maxWidth: 130 }} source={require("../assets/logoBlack.png")} />
				<Image style={{ resizeMode: "contain", flex: 1, height: 50, maxWidth: 100, marginHorizontal: 20 }} source={require("../assets/logoCocem.png")} />
				<Image style={{ resizeMode: "contain", flex: 1, height: 50, maxWidth: 130 }} source={require("../assets/logoMadrid.png")} />
			</View>
			<View style={{ height: 1, borderRadius: 2, width: "100%", maxWidth: 600, backgroundColor: "gray", opacity: 0.4, marginTop: 10 }} />
			<View style={{ alignItems: "center", flexWrap: "wrap", justifyContent: "center", flexDirection: "row", maxWidth: 600, width: "100%", paddingHorizontal: 10, marginVertical: 10 }}>
				{pages.map((page, i) => {
					return (
						<TouchableOpacity key={i} onPress={() => navigation.navigate("Page", { id: page.id })} style={{ flexDirection: "row", paddingHorizontal: 15 }}>
							<StyledText>{page.title}</StyledText>
						</TouchableOpacity>
					);
				})}
			</View>
			<View style={{ height: 1, borderRadius: 2, width: "100%", maxWidth: 600, backgroundColor: "gray", opacity: 0.4, marginBottom: 10 }} />
			<View style={{ marginTop: 0, opacity:.4, maxWidth: 600, width: "100%", paddingHorizontal: 10 }}>
				<StyledText style={{ textAlign: "center", fontSize: 12 }}>C/Diego de León, 50 - 28006 Madrid</StyledText>
				<StyledText style={{ textAlign: "center", fontSize: 12 }}>dgeneral@cocem.es</StyledText>
			</View>
		</View>
	);
};

export default Footer;

const styles = StyleSheet.create({
	header: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		paddingLeft: 15,
		paddingRight: 5,
		minHeight: 55,
		backgroundColor: "#FFC148",
		width: "100%",
		zIndex: 0,
	},
	logo: {
		height: 40,
		width: 150,
	},
});
